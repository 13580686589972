"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFutureDate = exports.formatDate = exports.diffDates = exports.calculateAge = void 0;

var diffDates = function diffDates(startDate, endDate) {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  return Math.round(Math.abs((startDate - endDate) / oneDay));
};

exports.diffDates = diffDates;

var calculateAge = function calculateAge(birthYear) {
  var diff_ms = Date.now() - birthYear.getTime();
  var age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

exports.calculateAge = calculateAge;

var formatDate = function formatDate(timestamp) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  return new Date(timestamp).toLocaleDateString('de-DE', options) || '';
};

exports.formatDate = formatDate;

var isFutureDate = function isFutureDate(timestamp) {
  var today = new Date();
  return today < timestamp;
};

exports.isFutureDate = isFutureDate;