"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectPcMessage = void 0;
var defaultSeverity = 'warning';

var selectPcMessage = function selectPcMessage(_ref) {
  var error = _ref.error,
      _ref$severity = _ref.severity,
      severity = _ref$severity === void 0 ? defaultSeverity : _ref$severity;
  var text = '';

  switch (error.code) {
    case 'LimitExceededException':
      text = "pc-matching:generic.Validate.Error.LimitExceededException";
      break;

    case 'UserNotFoundException':
      text = "pc-matching:generic.Validate.Error.UserNotFoundException";
      break;

    case 'No current user':
      text = "pc-matching:generic.Validate.Error.UserNotFoundException";
      break;

    case 'InvalidParameterException':
      text = "pc-matching:generic.Validate.Error.InvalidParameterException";
      break;

    case 'ExpiredCodeException':
      text = "pc-matching:generic.Validate.Error.ExpiredCodeException";
      break;

    default:
      text = "pc-matching:generic.Validate.Error.default";
      break;
  }

  return {
    text: text,
    severity: severity
  };
};

exports.selectPcMessage = selectPcMessage;