"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

// This function takes in latitude and longitude of two location
// and returns the distance between them as the crow flies (in km)
var calcDistance = function calcDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km

  if (lat1 && lat1 > 0) {
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  } else {
    return 0;
  }
}; // Converts numeric degrees to radians


var toRad = function toRad(Value) {
  return Value * Math.PI / 180;
};

var _default = calcDistance;
exports.default = _default;