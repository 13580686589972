"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackEvent = trackEvent;
exports.trackPayload = trackPayload;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _libraryUtils = require("@jobmatchme/library-utils");

function trackPayload(_ref) {
  var eventName = _ref.eventName,
      payload = _ref.payload;
  var trackingPayload = {
    eventName: eventName,
    timestamp: Date.now(),
    payload: payload
  };
  fetch("".concat(_libraryUtils.appConfig.routes.comApi, "/trackingService/create"), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json'
    },
    body: JSON.stringify(trackingPayload)
  });
}

function trackEvent(_ref2) {
  var eventName = _ref2.eventName,
      pcUuid = _ref2.pcUuid,
      payload = _ref2.payload;
  trackPayload({
    eventName: eventName,
    payload: (0, _extends2.default)({}, payload, {
      pcUuid: pcUuid
    })
  });
}