"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.amplifyPcConfig = void 0;

var _Base = require("./Base");

var amplifyPcConfig = _Base.amplifyConfig;
exports.amplifyPcConfig = amplifyPcConfig;