"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pc = exports.default = exports.bc = void 0;

var _common = require("../common");

var MIN_AGE = 16,
    MAX_AGE = 91,
    maxYear = new Date().getFullYear() - MIN_AGE,
    minYear = new Date().getFullYear() - MAX_AGE;
var availabilityDate = _common.commonComponentConfigurations.availabilityDate,
    additionalBenefits = _common.commonComponentConfigurations.additionalBenefits,
    bcName = _common.commonComponentConfigurations.bcName,
    externalName = _common.commonComponentConfigurations.externalName,
    generatedExternalName = _common.commonComponentConfigurations.generatedExternalName,
    wizardBcRegistration = _common.commonComponentConfigurations.wizardBcRegistration,
    wizardBcDirectRegistration = _common.commonComponentConfigurations.wizardBcDirectRegistration,
    internalName = _common.commonComponentConfigurations.internalName,
    name = _common.commonComponentConfigurations.name,
    other = _common.commonComponentConfigurations.other,
    phone = _common.commonComponentConfigurations.phone,
    email = _common.commonComponentConfigurations.email,
    nameSimplified = _common.commonComponentConfigurations.nameSimplified,
    phoneSimplified = _common.commonComponentConfigurations.phoneSimplified,
    emailSimplified = _common.commonComponentConfigurations.emailSimplified;
var workExperience = _common.commonCallableComponentConfigurations.workExperience,
    wizardLocation = _common.commonCallableComponentConfigurations.wizardLocation,
    location = _common.commonCallableComponentConfigurations.location,
    languages = _common.commonCallableComponentConfigurations.languages;
var profilePrefix = 'profile-properties:service-gastro.';
var componentConfigurations = {
  locationWithoutRadius: location({
    translationPrefix: 'common',
    withRadius: false
  }),
  wizardLocationWithoutRadius: wizardLocation({
    translationPrefix: 'common',
    withRadius: false
  }),
  birthYear: {
    component: 'DropDown',
    valueType: 'Number',
    valueName: 'birthYear',
    defaultValue: null,
    numberRange: {
      minimum: minYear,
      maximum: maxYear,
      order: 'descending'
    },
    mandatory: true,
    translations: {
      text: 'common.birthYear.text',
      selectPlaceholderText: 'common.birthYear.selectPlaceholderText',
      label: 'common.birthYear.label'
    }
  },
  salary: {
    component: 'RangeSlider',
    valueType: 'NumberRange',
    valueName: 'salary',
    defaultValue: {
      minimum: 1850,
      maximum: 2450
    },
    range: {
      minimum: 450,
      maximum: 5000
    },
    stepSize: 50,
    sliderButtons: ['minimum', 'maximum'],
    icon: 'MonetizationOn',
    mandatory: false,
    translations: {
      label: 'common.salary.label',
      text: 'common.salary.text',
      hint: "common.salary.hint",
      brutto: 'common.salary.brutto',
      currency: 'common.salary.currency'
    }
  },
  workTypes: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'workTypes',
    defaultValue: [],
    options: {
      SERVICE: profilePrefix + 'workTypes.SERVICE',
      BAR: profilePrefix + 'workTypes.BAR',
      DELIVERY: profilePrefix + 'workTypes.DELIVERY',
      KITCHEN: profilePrefix + 'workTypes.KITCHEN',
      SALES: profilePrefix + 'workTypes.SALES',
      CLEANING: profilePrefix + 'workTypes.CLEANING',
      HOTEL_INDUSTRY: profilePrefix + 'workTypes.HOTEL_INDUSTRY',
      WAREHOUSE: profilePrefix + 'workTypes.WAREHOUSE'
    },
    icon: 'Business',
    mandatory: false,
    translations: {
      label: 'service-gastro.workTypes.label',
      text: 'service-gastro.workTypes.text',
      hint: _common.MULTIPLE_ANSWERS
    },
    educationTooltip: {
      content: 'service-gastro.workTypes.info',
      target: 'workTypes',
      offset: 10,
      position: 14,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  languages: languages({}),
  optionalLanguages: languages({
    unsetOption: true
  }),
  practice: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'practice',
    defaultValue: [],
    options: {
      KITCHEN: profilePrefix + 'practice.KITCHEN',
      RESTAURANT: profilePrefix + 'practice.RESTAURANT',
      SERVICE_AGENT: profilePrefix + 'practice.SERVICE_AGENT',
      CAFETERIA: profilePrefix + 'practice.CAFETERIA',
      DELIVERY_SERVICE: profilePrefix + 'practice.DELIVERY_SERVICE',
      HOTEL_INDUSTRY: profilePrefix + 'practice.HOTEL_INDUSTRY',
      WAREHOUSE: profilePrefix + 'workTypes.WAREHOUSE',
      BAR: profilePrefix + 'practice.BAR',
      BARISTA: profilePrefix + 'practice.BARISTA',
      SALES: profilePrefix + 'practice.SALES',
      CLEANING: profilePrefix + 'practice.CLEANING',
      MISCELLANEOUS: profilePrefix + 'practice.MISCELLANEOUS'
    },
    icon: 'Business',
    translations: {
      label: 'service-gastro.practice.label',
      text: 'service-gastro.practice.text',
      hint: 'service-gastro.practice.hint'
    },
    conditions: [{
      valueName: 'workExperience',
      relation: 'NOT_CONTAINS',
      value: 'NONE'
    }]
  },
  employmentTypes: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'employmentTypes',
    defaultValue: [],
    options: {
      FULL_TIME: profilePrefix + 'employmentTypes.FULL_TIME',
      PART_TIME: profilePrefix + 'employmentTypes.PART_TIME',
      AUXILIARY: profilePrefix + 'employmentTypes.AUXILIARY',
      SHORT_TERM: profilePrefix + 'employmentTypes.SHORT_TERM'
    },
    icon: 'WatchLater',
    mandatory: false,
    translations: {
      label: 'service-gastro.employmentTypes.label',
      text: 'service-gastro.employmentTypes.text',
      hint: _common.MULTIPLE_ANSWERS
    },
    educationTooltip: {
      content: 'service-gastro.employmentTypes.info',
      target: 'employmentTypes',
      offset: 10,
      position: 11,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  tasks: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'tasks',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'service-gastro.tasks.label',
      text: 'service-gastro.tasks.text',
      inputField: 'service-gastro.tasks.inputField'
    }
  },
  wizardTasks: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'tasks',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'service-gastro.tasks.label',
      text: 'service-gastro.tasks.text',
      inputField: 'service-gastro.tasks.inputField',
      hint: 'common.wizardDafaultHint.hint'
    },
    educationTooltip: {
      content: 'service-gastro.tasks.info',
      target: 'wizardTasks',
      offset: 10,
      position: 9,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  workTimes: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'workTimes',
    defaultValue: [],
    options: {
      WEEKDAYS: profilePrefix + 'workTimes.WEEKDAYS',
      WEEKEND: profilePrefix + 'workTimes.WEEKEND',
      MORNING: profilePrefix + 'workTimes.MORNING',
      AFTERNOON: profilePrefix + 'workTimes.AFTERNOON',
      EVENING: profilePrefix + 'workTimes.EVENING'
    },
    icon: 'WatchLater',
    mandatory: false,
    translations: {
      label: 'service-gastro.workTimes.label',
      text: 'service-gastro.workTimes.text',
      hint: _common.MULTIPLE_ANSWERS
    }
  },
  legalAgeRequired: {
    component: 'SingleChoice',
    valueType: 'Boolean',
    valueName: 'legalAgeRequired',
    defaultValue: null,
    options: {
      true: profilePrefix + 'legalAgeRequired.true',
      false: profilePrefix + 'legalAgeRequired.false'
    },
    icon: 'DriveEta',
    mandatory: false,
    translations: {
      label: profilePrefix + 'legalAgeRequired.label',
      text: profilePrefix + 'legalAgeRequired.text',
      hint: profilePrefix + 'legalAgeRequired.hint'
    }
  },
  driversLicense: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'driversLicense',
    defaultValue: [],
    options: {
      AM: profilePrefix + 'driversLicense.AM',
      B: profilePrefix + 'driversLicense.B',
      C1: profilePrefix + 'driversLicense.C1',
      NONE: profilePrefix + 'driversLicense.NONE'
    },
    icon: 'WatchLater',
    mandatory: false,
    translations: {
      label: 'service-gastro.driversLicense.label',
      text: 'service-gastro.driversLicense.text',
      hint: _common.MULTIPLE_ANSWERS
    },
    mutualOptions: {
      C1: [{
        key: 'AM',
        value: true
      }, {
        key: 'B',
        value: true
      }, {
        key: 'NONE',
        value: false
      }],
      B: [{
        key: 'AM',
        value: true
      }, {
        key: 'NONE',
        value: false
      }],
      AM: [{
        key: 'NONE',
        value: false
      }],
      NONE: [{
        key: 'AM',
        value: false
      }, {
        key: 'B',
        value: false
      }, {
        key: 'C1',
        value: false
      }]
    },
    optionIcons: {
      B: 'gastro_B',
      C1: 'gastro_C1',
      AM: 'gastro_AM'
    },
    optionIconsClass: 'position'
  },
  additionalBenefits: additionalBenefits,
  other: other,
  workExperience: workExperience({
    translationPrefix: 'service-gastro',
    defaultValue: []
  }),
  externalName: externalName,
  generatedExternalName: generatedExternalName,
  wizardBcRegistration: wizardBcRegistration,
  wizardBcDirectRegistration: wizardBcDirectRegistration,
  internalName: internalName,
  bcName: bcName,
  // Personal information of the PC registration
  name: name,
  phone: phone,
  email: email,
  availabilityDate: availabilityDate,
  nameSimplified: nameSimplified,
  phoneSimplified: phoneSimplified,
  emailSimplified: emailSimplified
};
var pc = {
  discriminator: 'service-gastro',
  version: '1.0.1',
  target: 'pc',
  vertical: {
    de: 'service-gastro',
    at: 'service-gastro',
    en: 'service-gastro',
    pl: 'obsluga-gastronomiczna'
  },
  // Components used in this configuration
  componentConfigurations: componentConfigurations,
  translationConfiguration: {
    namespace: 'pc-funnel'
  },
  // Configuration of the Funnel
  funnel: [{
    steps: [{
      componentConfiguration: 'locationWithoutRadius'
    }, {
      componentConfiguration: 'workTypes'
    }, {
      componentConfiguration: 'employmentTypes'
    }, {
      componentConfiguration: 'workTimes'
    }, {
      componentConfiguration: 'driversLicense'
    }, {
      componentConfiguration: 'salary'
    }, {
      componentConfiguration: 'workExperience'
    }, {
      componentConfiguration: 'languages'
    }, {
      componentConfiguration: 'practice'
    }, {
      componentConfiguration: 'birthYear'
    }, {
      componentConfiguration: 'availabilityDate'
    }]
  }],
  simplifiedFunnelQuestions: [{
    steps: []
  }, {
    translations: {
      text: 'common.funnelSeparatorSimpflified.text',
      description: 'common.funnelSeparatorSimpflified.description',
      nextButton: 'common.funnelSeparatorSimpflified.nextButton'
    },
    steps: [{
      componentConfiguration: 'locationWithoutRadius'
    }, {
      componentConfiguration: 'workTypes'
    }, {
      componentConfiguration: 'employmentTypes'
    }, {
      componentConfiguration: 'workTimes'
    }, {
      componentConfiguration: 'driversLicense'
    }, {
      componentConfiguration: 'salary'
    }, {
      componentConfiguration: 'workExperience'
    }, {
      componentConfiguration: 'languages'
    }, {
      componentConfiguration: 'practice'
    }, {
      componentConfiguration: 'birthYear'
    }, {
      componentConfiguration: 'availabilityDate'
    }]
  }],
  simplifiedRegistrationForm: [{
    userAttributes: {
      phone_number: 'phone',
      name: 'name',
      gender: 'gender'
    },
    steps: [{
      componentConfiguration: 'nameSimplified'
    }, {
      componentConfiguration: 'phoneSimplified'
    }, {
      componentConfiguration: 'emailSimplified'
    }]
  }],
  registration: [{
    userAttributes: {
      phone_number: 'phone',
      name: 'name',
      gender: 'gender'
    },
    steps: [{
      componentConfiguration: 'name'
    }, {
      componentConfiguration: 'phone'
    }, {
      componentConfiguration: 'email'
    }]
  }],
  edit: [{
    key: 'subtitle1',
    type: 'subtitle',
    translations: {
      headline: 'pc-funnel:service-gastro.edit.subtitle1.headline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'locationWithoutRadius'
  }, {
    type: 'item',
    componentConfiguration: 'workTypes'
  }, {
    type: 'item',
    componentConfiguration: 'employmentTypes'
  }, {
    type: 'item',
    componentConfiguration: 'workTimes'
  }, {
    type: 'item',
    componentConfiguration: 'driversLicense'
  }, {
    type: 'item',
    componentConfiguration: 'salary'
  }, {
    type: 'item',
    componentConfiguration: 'workExperience'
  }, {
    type: 'item',
    componentConfiguration: 'languages'
  }, {
    type: 'item',
    componentConfiguration: 'practice'
  }, {
    type: 'item',
    componentConfiguration: 'birthYear'
  }, {
    type: 'item',
    componentConfiguration: 'availabilityDate'
  }],
  startFreeWizard: [],
  startDirectActivationWizard: [],
  view: {
    profileFields: ['employmentTypes', 'workTimes', 'driversLicense', 'practice', 'workExperience', 'birthYear', 'languages', 'salary'],
    profileListFields: ['name', 'workTypes', 'distance', 'phone', 'email', 'date'],
    primaryAttribute: 'workTypes',
    translatedStringFields: ['workExperience', 'languages'],
    noCulture: true
  }
};
exports.pc = pc;

var wizardLogic = function wizardLogic(type) {
  return [{
    steps: [{
      componentConfiguration: 'locationWithoutRadius',
      mandatory: true
    }, {
      componentConfiguration: 'employmentTypes',
      mandatory: false
    }, {
      componentConfiguration: 'workTimes',
      mandatory: false
    }, {
      componentConfiguration: 'driversLicense',
      mandatory: false
    }, {
      componentConfiguration: 'workExperience',
      mandatory: false
    }, {
      componentConfiguration: 'workTypes',
      mandatory: false
    }, {
      componentConfiguration: 'salary',
      mandatory: false
    }]
  }, {
    translations: {
      text: 'common.funnelSeparatorFirstAudienceDone.text',
      description: 'common.funnelSeparatorFirstAudienceDone.description',
      nextButton: 'common.funnelSeparatorFirstAudienceDone.nextButton'
    },
    steps: [{
      componentConfiguration: 'additionalBenefits',
      mandatory: false
    }, {
      componentConfiguration: 'wizardTasks',
      mandatory: false
    }, {
      componentConfiguration: 'bcName',
      mandatory: true
    }, {
      componentConfiguration: 'generatedExternalName',
      mandatory: true
    }, {
      componentConfiguration: type,
      mandatory: true
    }]
  }];
};

var bc = {
  discriminator: 'service-gastro',
  version: '1.0.1',
  target: 'bc',
  // Components used in this configuration
  componentConfigurations: componentConfigurations,
  // Translation options
  translationConfiguration: {
    namespace: 'bc-dashboard'
  },
  // Configuration of the Funnel
  funnel: [{
    steps: [{
      componentConfiguration: 'locationWithoutRadius',
      mandatory: true
    }, {
      componentConfiguration: 'workTypes',
      mandatory: false
    }, {
      componentConfiguration: 'employmentTypes',
      mandatory: false
    }, {
      componentConfiguration: 'workTimes',
      mandatory: false
    }, {
      componentConfiguration: 'driversLicense',
      mandatory: false
    }, {
      componentConfiguration: 'legalAgeRequired',
      mandatory: false
    }, {
      componentConfiguration: 'salary',
      mandatory: false
    }, {
      componentConfiguration: 'workExperience',
      mandatory: false
    }, {
      componentConfiguration: 'optionalLanguages'
    }, {
      componentConfiguration: 'practice',
      mandatory: false
    }]
  }, {
    translations: {
      text: 'common.funnelSeparatorAudienceDone.text',
      description: 'common.funnelSeparatorAudienceDone.description',
      nextButton: 'common.funnelSeparatorAudienceDone.nextButton'
    },
    steps: [{
      componentConfiguration: 'tasks',
      mandatory: false
    }, {
      componentConfiguration: 'additionalBenefits',
      mandatory: false
    }, {
      componentConfiguration: 'other',
      mandatory: false
    }, {
      componentConfiguration: 'generatedExternalName',
      mandatory: false
    }]
  }],
  startFreeWizard: wizardLogic('wizardBcRegistration'),
  startDirectActivationWizard: wizardLogic('wizardBcDirectRegistration'),
  edit: [{
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.AudienceHeadline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'locationWithoutRadius'
  }, {
    type: 'item',
    componentConfiguration: 'workTypes'
  }, {
    type: 'item',
    componentConfiguration: 'employmentTypes'
  }, {
    type: 'item',
    componentConfiguration: 'workTimes'
  }, {
    type: 'item',
    componentConfiguration: 'driversLicense'
  }, {
    type: 'item',
    componentConfiguration: 'legalAgeRequired'
  }, {
    type: 'item',
    componentConfiguration: 'salary'
  }, {
    type: 'item',
    componentConfiguration: 'workExperience'
  }, {
    type: 'item',
    componentConfiguration: 'optionalLanguages'
  }, {
    type: 'item',
    componentConfiguration: 'practice'
  }, {
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.AdvertHeadline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'tasks',
    isEmpty: function isEmpty(value) {
      return !value || !value.tasks;
    }
  }, {
    type: 'item',
    componentConfiguration: 'additionalBenefits',
    isEmpty: function isEmpty(value) {
      return !value || !value.additionalBenefits;
    }
  }, {
    type: 'item',
    componentConfiguration: 'other',
    isEmpty: function isEmpty(value) {
      return !value || !value.other;
    }
  }, {
    type: 'item',
    componentConfiguration: 'internalName',
    isEmpty: function isEmpty() {
      return false;
    }
  }, {
    type: 'item',
    componentConfiguration: 'externalName',
    isEmpty: function isEmpty() {
      return false;
    }
  }, {
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.SettingsHeadline',
      headlineFreemium: 'bc-dashboard:JobofferEdit.SettingsHeadlineFreemium',
      description: 'bc-dashboard:JobofferEdit.SettingsDescription'
    }
  }],
  view: {
    primaryAttribute: 'workTypes'
  }
};
exports.bc = bc;
var _default = {
  pc: pc,
  bc: bc
};
exports.default = _default;