"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactI18next = require("react-i18next");

var useTranslationNamespace = function useTranslationNamespace(namespace) {
  var _useTranslation = (0, _reactI18next.useTranslation)(),
      t = _useTranslation.t;

  return function (id, defaultValue) {
    return t("".concat(namespace, ".").concat(id), defaultValue);
  };
};

var _default = useTranslationNamespace;
exports.default = _default;