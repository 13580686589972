"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TIMING = exports.REGISTRATION = exports.PKFUNNEL = exports.PAGEVIEW = exports.MULTIPLE = exports.EVENT = exports.ANALYTICS = void 0;
exports.default = track;
exports.sendABDataToGA = sendABDataToGA;
exports.trackEvent = trackEvent;
exports.trackIt = trackIt;
exports.trackPageview = trackPageview;
exports.trackPkFunnelSteps = trackPkFunnelSteps;
exports.trackPkFunnelStepsWithDiscriminator = trackPkFunnelStepsWithDiscriminator;
exports.trackRegistration = trackRegistration;
exports.trackVideoPlay = trackVideoPlay;
exports.types = void 0;

var _reactGtmModule = _interopRequireDefault(require("react-gtm-module"));

var ANALYTICS = 'ANALYTICS';
exports.ANALYTICS = ANALYTICS;
var MULTIPLE = 'MULTIPLE';
exports.MULTIPLE = MULTIPLE;
var PAGEVIEW = 'PAGEVIEW';
exports.PAGEVIEW = PAGEVIEW;
var EVENT = 'EVENT';
exports.EVENT = EVENT;
var TIMING = 'TIMING';
exports.TIMING = TIMING;
var REGISTRATION = 'REGISTRATION';
exports.REGISTRATION = REGISTRATION;
var PKFUNNEL = 'PKFUNNEL';
exports.PKFUNNEL = PKFUNNEL;
var types = {
  MULTIPLE: MULTIPLE,
  PAGEVIEW: PAGEVIEW,
  EVENT: EVENT,
  TIMING: TIMING,
  REGISTRATION: REGISTRATION,
  PKFUNNEL: PKFUNNEL
};
exports.types = types;

/**
 * Track an event
 *
 * @param {Object} fields The tracking parameters
 *
 * @returns {void}
 */
var sendToGtagManager = function sendToGtagManager(url, name, title) {
  if (navigator.userAgent !== 'ReactSnap') {
    var tagManagerArgs = {
      dataLayer: {
        page: url,
        event: name,
        pageTitle: title
      },
      dataLayerName: 'PageDataLayer'
    };

    _reactGtmModule.default.dataLayer(tagManagerArgs);
  }
};
/**
 * @param {*} findString find vertical in url as example /pflege/
 */


var findStringInUrl = function findStringInUrl(findString) {
  var url = window.location.href;
  return url.includes(findString);
};

var getVertical = function getVertical() {
  return findStringInUrl('/pflege/') ? 'pflege' : 'truck';
};

function trackEvent(_ref) {
  var _ref$eventCategory = _ref.eventCategory,
      eventCategory = _ref$eventCategory === void 0 ? 'unknown' : _ref$eventCategory,
      _ref$eventAction = _ref.eventAction,
      eventAction = _ref$eventAction === void 0 ? 'unknown' : _ref$eventAction,
      _ref$eventLabel = _ref.eventLabel,
      eventLabel = _ref$eventLabel === void 0 ? 'unknown' : _ref$eventLabel,
      eventValue = _ref.eventValue;

  if (navigator.userAgent !== 'ReactSnap') {
    var category = getVertical() + ' ' + eventCategory;
    var tagManagerArgs = {
      gtmId: process.env.RAZZLE_GTM_ID,
      events: {
        event_category: category,
        event_label: eventLabel,
        event_action: eventAction,
        value: eventValue,
        event: category
      },
      dataLayerName: 'PageDataLayer'
    };

    _reactGtmModule.default.dataLayer(tagManagerArgs);
  }
}

/**
 * Track a screen view
 *
 * @param {Object} fields The tracking parameters
 *
 * @returns {void}
 */
function trackPageview(_ref2) {
  var page = _ref2.page,
      title = _ref2.title;

  if (page === 'matches/details') {
    page = getVertical() + '/matches/details';
  }

  setTimeout(function () {
    sendToGtagManager(page, 'pageView', title);
  }, 200);
  /* eslint-enable camelcase */
}
/*
 * Track a video play
 *
 * @param {Object} fields The tracking parameters
 *
 * @returns {void}
 */


function trackVideoPlay(_ref3) {
  var label = _ref3.label,
      videoId = _ref3.videoId;
  trackEvent({
    eventCategory: 'Video',
    eventAction: 'videoPlay',
    eventLabel: label,
    eventValue: 0
  });
  /* eslint-enable camelcase */
}

/* eslint-disable prefer-template */
// track analytics
function trackIt(url, name, title) {
  sendToGtagManager(url, name, title);
}

var getCountry = function getCountry() {
  var currentPath = window.location.pathname;
  var country = 'de'; //default value

  if (currentPath.indexOf('/de/') > -1) {
    country = 'de';
  }

  if (currentPath.indexOf('/at/') > -1) {
    country = 'at';
  }

  return country;
};
/**
 * Track a pk funnel steps
 *
 * @param {string} step The step of pk funnel
 *
 * @returns {void}
 */


function trackPkFunnelSteps(step) {
  var country = getCountry();
  sendToGtagManager("/".concat(country, "/").concat(getVertical(), "/fahrer/jobsuche").concat(step ? '/' + step : ''), 'pkFunnel', 'PK Funnel');
}

function trackPkFunnelStepsWithDiscriminator(_ref4) {
  var step = _ref4.step,
      discriminator = _ref4.discriminator,
      configKey = _ref4.configKey,
      target = _ref4.target;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  setTimeout(function () {
    sendToGtagManager(target && target.toUpperCase() + 'Funnel: step: ' + configKey + '-' + step, target && target.toUpperCase() + ' Funnel ' + capitalizeFirstLetter(discriminator), target && target.toUpperCase() + ' Funnel ' + capitalizeFirstLetter(discriminator));
  }, 150);
}
/**
 * Track a registration
 *
 * @param {string} type The type of registration in case there are
 *                      several per app
 *
 * @returns {void}
 */


function trackRegistration(type) {
  sendToGtagManager("/registration/completed".concat(type ? '/' + type : ''), 'Registration', getVertical() + ' Registration ' + type);
}

function sendABDataToGA(ABTEST_TAG, url, title) {
  try {
    var variant = '';
    var ls = localStorage.getItem(ABTEST_TAG);
    variant = ls ? ls : '';
    trackIt("".concat(url, " ").concat(variant), "".concat(title).concat(ABTEST_TAG), "".concat(title).concat(ABTEST_TAG));
  } catch (error) {
    return console.error('Error: ', error);
  }
}
/* eslint-enable prefer-template */


/**
 * Track an analytics event
 *
 * @param {AnalyticsMetaActions} action The tracking actions
 *
 * @returns {void}
 */
function track(action) {
  switch (action.type) {
    case types.MULTIPLE:
      action.payload.map(track);
      break;

    case types.PAGEVIEW:
      trackPageview(action.payload);
      break;

    case types.EVENT:
      trackEvent(action.payload);
      break;

    case types.PKFUNNEL:
      trackPkFunnelSteps(action.payload);
      break;

    case types.REGISTRATION:
      trackRegistration(action.payload);
      break;

    default:
  }
}