"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var truckConfig = {
  discriminator: 'truck',
  global: [{
    key: 'companyName',
    backend: 'match.displayName',
    default: ''
  }, {
    key: 'bcName',
    backend: 'match.bcName',
    default: ''
  }, {
    key: 'searchLocation',
    backend: 'searchProfile.location',
    default: {
      latitude: '55',
      longitude: '10'
    }
  }, {
    key: 'jobLocation',
    backend: 'match.joboffer.location',
    default: {
      latitude: '55',
      longitude: '10'
    }
  }],
  cardLines: [{
    icon: 'location',
    backend: 'match.joboffer.location.name',
    default: ''
  }, {
    icon: 'timelapse',
    key: 'employmentTypes',
    backend: 'match.joboffer.employmentTypes',
    default: ''
  }, {
    icon: 'truck',
    key: 'transportDistances',
    backend: 'match.joboffer.transportDistances',
    default: []
  }, {
    icon: 'watchLater',
    key: 'worktimes',
    backend: 'match.joboffer.worktimes',
    default: ''
  }, {
    icon: 'monetization',
    tKey: 'monetization'
  }, {
    icon: 'favorite',
    tKey: 'favorite'
  }],
  previewLines: [{
    icon: 'monetization',
    tKey: 'monetizationApt'
  }, {
    icon: 'truck',
    key: 'transportDistances',
    backend: 'match.joboffer.transportDistances',
    default: []
  }, {
    primary: true,
    icon: 'location',
    backend: 'match.joboffer.location.name',
    default: ''
  }, {
    primary: true,
    icon: 'companyCulture',
    tKey: 'favoriteApt',
    default: ''
  }, {
    primary: true,
    icon: 'dots',
    tKey: '',
    default: ''
  }],
  descriptionLines: [{
    header: {
      tKey: 'additionalBenefits',
      common: true
    },
    backend: 'match.joboffer.descriptions.additionalBenefits',
    default: ''
  }, {
    header: {
      tKey: 'income',
      common: true
    },
    backend: 'match.joboffer.descriptions.income',
    default: ''
  }, {
    header: {
      tKey: 'tasks',
      common: true
    },
    backend: 'match.joboffer.descriptions.tasks',
    default: ''
  }, {
    header: {
      tKey: 'companyCulture',
      common: true
    },
    backend: 'match.joboffer.descriptions.companyCulture',
    default: ''
  }, {
    header: {
      tKey: 'transport'
    },
    backend: 'match.joboffer.descriptions.transport',
    default: ''
  }, {
    header: {
      tKey: 'comment',
      common: true
    },
    backend: 'match.joboffer.descriptions.comment',
    default: ''
  }, {
    header: {
      tKey: 'takeTheTruck'
    },
    backend: 'match.joboffer.takeTheTruck',
    default: ''
  }]
};
var _default = truckConfig;
exports.default = _default;