"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVertcalsResource = exports.fetchVerticalsPromise = void 0;

var _Pc = require("@jobmatchme/amplify-tools/es/provider/Pc");

var _Resource = require("@jobmatchme/suspendable-resource/es/Resource");

var fetchVerticalsPromise = function fetchVerticalsPromise() {
  return import('@jobmatchme/amplify-tools/es/api/Pc').then(function (_ref) {
    var PcApi = _ref.PcApi;
    return PcApi.get('/pcProfileService/rest/profileTypes', {
      'Content-Type': 'application/json'
    }).catch(function () {
      return undefined;
    });
  });
};

exports.fetchVerticalsPromise = fetchVerticalsPromise;

var resourceGetter = function resourceGetter() {
  return (0, _Pc.getUserState)().then(function (_ref2) {
    var isAuthenticated = _ref2.isAuthenticated;
    return isAuthenticated ? fetchVerticalsPromise() : undefined;
  });
};

var getVertcalsResource = function getVertcalsResource() {
  return new _Resource.Resource(resourceGetter);
};

exports.getVertcalsResource = getVertcalsResource;