"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iconPool = void 0;

/**
 * An object that defines the dynamic imports for icons.
 * Each key corresponds to a specific icon and the associated value
 * is a function that performs the dynamic import of that icon.
 */
var iconPool = {
  help: function help() {
    return import('@material-ui/icons/Help').then(function (m) {
      return m.default;
    });
  },
  team: function team() {
    return import('@material-ui/icons/Group').then(function (m) {
      return m.default;
    });
  },
  location: function location() {
    return import('@material-ui/icons/Room').then(function (m) {
      return m.default;
    });
  },
  school: function school() {
    return import('@material-ui/icons/School').then(function (m) {
      return m.default;
    });
  },
  business: function business() {
    return import('@material-ui/icons/Business').then(function (m) {
      return m.default;
    });
  },
  favorite: function favorite() {
    return import('@material-ui/icons/Favorite').then(function (m) {
      return m.default;
    });
  },
  language: function language() {
    return import('@material-ui/icons/Language').then(function (m) {
      return m.default;
    });
  },
  truck: function truck() {
    return import('@material-ui/icons/LocalShipping').then(function (m) {
      return m.default;
    });
  },
  timelapse: function timelapse() {
    return import('@material-ui/icons/Timelapse').then(function (m) {
      return m.default;
    });
  },
  watchLater: function watchLater() {
    return import('@material-ui/icons/WatchLater').then(function (m) {
      return m.default;
    });
  },
  monetization: function monetization() {
    return import('@material-ui/icons/MonetizationOn').then(function (m) {
      return m.default;
    });
  },
  localHospital: function localHospital() {
    return import('@material-ui/icons/LocalHospital').then(function (m) {
      return m.default;
    });
  },
  restaurantMenu: function restaurantMenu() {
    return import('@material-ui/icons/RestaurantMenu').then(function (m) {
      return m.default;
    });
  }
};
exports.iconPool = iconPool;