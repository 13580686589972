"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PcApi = void 0;

var _Base = require("./Base");

var _Pc = require("../config/Pc");

var loadPcAuth = function loadPcAuth() {
  return import(
  /* webpackChunkName: 'amplifyAuthPc' */
  '../auth/Pc').then(function (module) {
    return module.PcAuth;
  });
};

var PcApi = (0, _Base.ApiConstructor)(_Pc.amplifyPcConfig, loadPcAuth);
exports.PcApi = PcApi;