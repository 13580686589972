"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _functions = require("@jobmatchme/ui/es/utils/functions");

var getTranslatedPath = function getTranslatedPath(type) {
  var lang = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var returnVal = '';
  var lng = lang !== '' ? lang : (0, _functions.getLocale)();

  var pathBC = function pathBC(lng) {
    var retVal = lng + '' === 'pl' ? 'pracodawca' : 'arbeitgeber';
    return lng + '/' + retVal;
  };

  var pathPC = function pathPC(lng) {
    return lng + '/';
  };

  var pcCare = function pcCare(lng) {
    var retVal = lng + '' === 'pl' ? 'pracodawca' : 'pflege';
    return lng + '/' + retVal;
  };

  var pcFunnelLogistic = function pcFunnelLogistic(lng) {
    var retVal1 = lng + '' === 'pl' ? 'logistyka' : 'logistik';
    var retVal2 = lng + '' === 'pl' ? 'szukaj-pracy' : 'suche';
    return lng + '/jobs/' + retVal1 + '/' + retVal2;
  };

  var forgotPasw = function forgotPasw(lng) {
    return 'forgot-password';
  };

  var pcMatchingSettings = function pcMatchingSettings(lng) {
    var retVal = lng + '' === 'pl' ? 'pracownik/app/ustawienia/' : 'arbeitnehmer/app/einstellungen/';
    return lng + '/' + retVal;
  };

  var pcImprint = function pcImprint(lng) {
    var retVal = lng + '' === 'pl' ? 'odcisk' : 'impressum';
    return lng + '/' + retVal + '/';
  };

  var pcTermsOfUse = function pcTermsOfUse(lng) {
    var retVal = lng + '' === 'pl' ? 'warunkikorzystania' : 'nutzungsbedingungen';
    return lng + '/' + retVal + '/';
  };

  var pcDataProtection = function pcDataProtection(lng) {
    var retVal = lng + '' === 'pl' ? 'politykaprywatnosci' : 'datenschutz';
    return lng + '/' + retVal + '/';
  };

  var pathDriver = function pathDriver(lng) {
    var retVal = lng + '' === 'pl' ? 'jobs/logistyka/szukaj-pracy' : 'jobs/logistik/suche';
    return lng + '/' + retVal;
  };

  switch (type) {
    case 'bc':
      returnVal = pathBC(lng);
      break;

    case 'pc':
      returnVal = pathPC(lng);
      break;

    case 'rootPath':
      returnVal = lng;
      break;

    case 'driver':
      returnVal = pathDriver(lng);
      break;

    case 'pcCare':
      returnVal = pcCare(lng);
      break;

    case 'pcImprint':
      returnVal = pcImprint(lng);
      break;

    case 'pcTermsOfUse':
      returnVal = pcTermsOfUse(lng);
      break;

    case 'pcDataProtection':
      returnVal = pcDataProtection(lng);
      break;

    case 'pcFunnelLogistic':
      returnVal = pcFunnelLogistic(lng);
      break;

    case 'pcMatchingSettings':
      returnVal = pcMatchingSettings(lng);
      break;

    case 'forgotPasw':
      returnVal = forgotPasw(lng);
      break;

    default:
      returnVal = '';
  }

  return returnVal;
};

var _default = getTranslatedPath;
exports.default = _default;