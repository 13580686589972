"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiEndpoint = exports.amplifyConfig = void 0;

var getHostName = function getHostName() {
  return typeof window !== 'undefined' && window.location ? window.location.hostname : 'www.truck-jobs.com';
};

var env = {
  DOMAIN_TYPE: process.env.REACT_APP_DOMAIN_TYPE || process.env.RAZZLE_DOMAIN_TYPE || 'truck',
  PC_PROFILE_APPSYNC: process.env.REACT_APP_PC_PROFILE_APPSYNC || process.env.RAZZLE_PC_PROFILE_APPSYNC || 'http://localhost:3000/graphql',
  PC_OAUTH_DOMAIN: process.env.REACT_APP_PC_OAUTH_DOMAIN || process.env.RAZZLE_PC_OAUTH_DOMAIN || 'auth.jobmatch.me',
  PC_PROFILE_COGNITO: process.env.REACT_APP_PC_PROFILE_COGNITO || process.env.RAZZLE_PC_PROFILE_COGNITO || 'eu-central-1_99OkJA7BN',
  PC_USER_POOL_WEBCLIENT_ID: process.env.REACT_APP_PC_USER_POOL_WEBCLIENT_ID || process.env.RAZZLE_PC_USER_POOL_WEBCLIENT_ID || '4rb5nnkpm1e8u3uogihh0udsu6',
  PC_IDENTITY_POOL_ID: process.env.REACT_APP_PC_IDENTITY_POOL_ID || process.env.RAZZLE_PC_IDENTITY_POOL_ID || 'eu-central-1:b8083483-6475-44f7-ad52-d02c431cae37',
  STAGE: process.env.REACT_APP_STAGE || process.env.RAZZLE_STAGE || 'dev'
};
var domainType = env.DOMAIN_TYPE; // https://demo-dev.auth.eu-central-1.amazoncognito.com//oauth2/authorize?identity_provider=Google&redirect_uri=http://localhost:3000/&response_type=CODE&client_id=6mg75ncs21f44b43ae3e7d4r1v&scope=aws.cognito.signin.user.admin email openid phone profile

var amplifyConfig = {
  aws_project_region: 'eu-central-1',
  aws_appsync_graphqlEndpoint: env.PC_PROFILE_APPSYNC,
  aws_appsync_region: 'eu-central-1',
  // We do not use a static global auth instance but configure according to cognito pool type (PC/BC).
  // The code relevant for auth can be found in ../api/
  aws_appsync_authenticationType: 'CUSTOM',
  aws_appsync_apiKey: '',
  Auth: {
    oauth: {
      // Domain name
      domain: env.PC_OAUTH_DOMAIN,
      // Authorized scopes
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // Callback URL
      redirectSignIn: domainType === 'truck' ? "https://".concat(getHostName(), "/de/fahrer/registrierung/") : "https://".concat(getHostName(), "/de/jobs/suche/registrierung/"),
      // Sign out URL
      redirectSignOut: "https://".concat(getHostName(), "/de/"),
      // 'code' for Authorization code grant,
      // 'token' for Implicit grant
      // Note that REFRESH token will only be generated when the responseType is code
      responseType: 'code'
    },
    mandatorySignIn: true,
    region: 'eu-central-1',
    userPoolId: env.PC_PROFILE_COGNITO,
    userPoolWebClientId: env.PC_USER_POOL_WEBCLIENT_ID,
    identityPoolId: env.PC_IDENTITY_POOL_ID
  },
  API: {
    endpoints: [{
      name: 'api-prod',
      endpoint: "https://".concat(getHostName(), "/api")
    }, {
      name: 'api-stage',
      endpoint: 'https://stage.truck-jobs.com/api'
    }, {
      name: 'api-dev',
      endpoint: 'http://localhost:3000/api'
    }, // For review stages
    !['dev', 'stage', 'prod'].includes(env.STAGE) ? {
      name: "api-".concat(env.STAGE),
      endpoint: "https://".concat(getHostName(), "/api")
    } : {}]
  }
};
exports.amplifyConfig = amplifyConfig;
var apiEndpoint = "api-".concat(env.STAGE);
exports.apiEndpoint = apiEndpoint;