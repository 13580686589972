"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useComponentTranslation = exports.default = void 0;

var _react = require("react");

var _ConfigProvider = require("../provider/ConfigProvider");

var _reactI18next = require("react-i18next");

var useOurTranslation = function useOurTranslation(prefix) {
  var _useTranslation = (0, _reactI18next.useTranslation)(),
      t = _useTranslation.t;

  return function (id, options) {
    return t("".concat(prefix, ".").concat(id), options);
  };
};

var useFallbackTranslationNamespace = function useFallbackTranslationNamespace(ns, options) {
  var _useTranslation2 = (0, _reactI18next.useTranslation)(),
      t = _useTranslation2.t;

  return function (id, defaultValue) {
    return t((id || '').includes(':') ? id : "".concat(ns, ":").concat(id), defaultValue || options);
  };
};

var useComponentTranslation = function useComponentTranslation(componentConfig) {
  var _useContext = (0, _react.useContext)(_ConfigProvider.ConfigurationContext),
      config = _useContext.config;

  var isEmpty = function isEmpty() {
    var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return Object.keys(obj).length === 0;
  };

  return useFallbackTranslationNamespace(componentConfig.staticTranslationPrefix || (isEmpty(config) ? componentConfig.namespace || "" : ((config === null || config === void 0 ? void 0 : config.translationConfiguration) || {}).namespace || ""), componentConfig.translationOptions);
};

exports.useComponentTranslation = useComponentTranslation;
var _default = useOurTranslation;
exports.default = _default;