"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadPlacesScript = exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _functions = require("@jobmatchme/ui/es/utils/functions");

/**
 * Attach the google script to the body we need this script for places select.
 */
var loadPlacesScript = function loadPlacesScript(setLoaded) {
  var script = document.createElement('script');
  script.id = 'googleMapsApi';
  script.src = 'https://maps.googleapis.com/maps/api/js?language=' + (0, _functions.getLocale)() + '&key=AIzaSyCxmKwdELLdYWxlOEzvYnMcNsrJXENF8Dg&libraries=places';
  script.defer = true;
  document.body.appendChild(script);

  if (setLoaded) {
    script.addEventListener('load', function () {
      setLoaded(true);
    });
    script.addEventListener('error', function (e) {
      console.error('script load error');
    });
    return function () {
      script.removeEventListener('load', function () {
        setLoaded(true);
      });
      script.addEventListener('error', function (e) {
        console.error('script load error');
      });
    };
  }
};

exports.loadPlacesScript = loadPlacesScript;

var usePlacesScript = function usePlacesScript() {
  var _useState = (0, _react.useState)(!!document.getElementById('googleMapsApi')),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      loaded = _useState2[0],
      setLoaded = _useState2[1];

  (0, _react.useEffect)(function () {
    if (!loaded && typeof window !== 'undefined') {
      return loadPlacesScript(setLoaded);
    }
  }, [loaded]);
  return loaded;
};

var _default = usePlacesScript;
exports.default = _default;