"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _care = _interopRequireDefault(require("./config/health/care"));

var _truck = _interopRequireDefault(require("./config/logistics/truck"));

var _scheduler = _interopRequireDefault(require("./config/logistics/scheduler"));

var _gastro = _interopRequireDefault(require("./config/service/gastro"));

var configMap = {
  care: _care.default,
  truck: _truck.default,
  'logistics-scheduler': _scheduler.default,
  'service-gastro': _gastro.default
};

var _default = function _default(vertical) {
  return configMap[vertical];
};

exports.default = _default;