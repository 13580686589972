import React from 'react'
import {pc} from '@jobmatchme/profile-components/es/resources/config/logistics/truck'
import LoginHeaderButtons from '@jobmatchme/ui/es/components/LoginHeaderButtons'
import RedirectAuthenticated from '@jobmatchme/pc-matching/es/components/RedirectAuthenticated'
import {useNavigate} from 'react-router-dom'
import ConfiguredFunnel from '@jobmatchme/pc-funnel/es/components/ConfiguredFunnel/lazy'
import ConfiguredRegistration from '@jobmatchme/pc-funnel/es/components/ConfiguredRegistration/lazy'
import MatchesScreen from '@jobmatchme/pc-funnel/es/components/MatchesScreen/lazy'
import StoreProfile from '@jobmatchme/pc-funnel/es/components/StoreProfile'
import {withRoutes} from '@jobmatchme/routing/es/provider/External'
import constructRegistration, {
  Loader
} from '@jobmatchme/pc-funnel/es/components/constructRegistration'

export const TruckJobsFunnel = withRoutes(({applicationRoutes}) => {
  const navigate = useNavigate()

  return (
    <>
      <RedirectAuthenticated redirect={applicationRoutes['pcmatchinghome']}>
        <ConfiguredFunnel
          variant="truck"
          config={pc}
          actionButton={LoginHeaderButtons}
          onComplete={() => {
            sessionStorage.setItem('pc-profile-finished', 'true')
            navigate(applicationRoutes['pcregtruck'])
          }}
        />
      </RedirectAuthenticated>
    </>
  )
})

export const TruckJobsRegistration = withRoutes(({applicationRoutes}) => {
  return (
    <ConfiguredRegistration
      variant="truck"
      actionButton={LoginHeaderButtons}
      config={pc}
      progressStepperVariant="text"
    >
      <StoreProfile
        Loader={Loader}
        RegistrationComponent={constructRegistration({
          config: pc,
          progressStepperVariant: 'text',
          MatchesScreen
        })}
        targetPaths={{
          onRegistration: `${applicationRoutes['pcmatching']}home/success/sign-up/truck/`,
          onSkip: applicationRoutes['pcmatchinghome']
        }}
      />
    </ConfiguredRegistration>
  )
})
