"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//Care Config
var careConfig = {
  discriminator: 'care',
  global: [{
    key: 'companyName',
    backend: 'match.displayName',
    default: ''
  }, {
    key: 'bcName',
    backend: 'match.bcName',
    default: ''
  }, {
    key: 'searchLocation',
    backend: 'searchProfile.location',
    default: {
      latitude: '55',
      longitude: '10'
    }
  }, {
    key: 'jobLocation',
    backend: 'match.joboffer.location',
    default: {
      latitude: '55',
      longitude: '10'
    }
  }],
  cardLines: [{
    icon: 'location',
    backend: 'match.joboffer.location.name',
    default: ''
  }, {
    icon: 'business',
    key: 'workTypes',
    backend: 'match.joboffer.workTypes',
    default: []
  }, {
    icon: 'localHospital',
    key: 'workPlace',
    backend: 'match.joboffer.workPlace',
    default: ''
  }, {
    icon: 'school',
    key: 'training',
    backend: 'match.joboffer.training',
    default: []
  }, {
    icon: 'watchLater',
    key: 'workTimes',
    special: true,
    backend: 'match.joboffer.workTimes',
    default: ''
  }, {
    icon: 'monetization',
    tKey: 'monetization'
  }, {
    icon: 'favorite',
    tKey: 'favorite',
    default: ''
  }],
  previewLines: [{
    icon: 'monetization',
    tKey: 'monetizationApt'
  }, {
    icon: 'school',
    key: 'training',
    backend: 'match.joboffer.training'
  }, {
    primary: true,
    icon: 'location',
    backend: 'match.joboffer.location.name',
    default: ''
  }, {
    primary: true,
    icon: 'companyCulture',
    tKey: 'favoriteApt',
    default: ''
  }, {
    primary: true,
    icon: 'dots',
    tKey: '',
    default: ''
  }],
  descriptionLines: [{
    header: {
      tKey: 'additionalBenefits',
      common: true
    },
    backend: 'match.joboffer.descriptions.additionalBenefits',
    default: ''
  }, {
    header: {
      tKey: 'tasks',
      common: true
    },
    backend: 'match.joboffer.descriptions.tasks',
    default: ''
  }, {
    header: {
      tKey: 'workPosition'
    },
    key: 'workPosition',
    backend: 'match.joboffer.workPosition'
  }, {
    header: {
      tKey: 'department'
    },
    backend: 'match.joboffer.descriptions.department',
    default: ''
  }, {
    header: {
      tKey: 'companyCulture',
      common: true
    },
    backend: 'match.joboffer.descriptions.companyCulture',
    default: ''
  }]
};
var _default = careConfig;
exports.default = _default;