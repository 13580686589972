"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fitDescriptionText = void 0;
exports.getFit = getFit;

function getFit(fit, itemMissingInProfile, itemMissingInJoboffer) {
  return fit >= 70 ? 'high' : fit > 30 ? 'medium' : fit === 0 && itemMissingInProfile && itemMissingInJoboffer ? 'empty' : fit === 0 && itemMissingInJoboffer ? 'low' : 'low';
}

var getLowFitText = function getLowFitText(bcValue, pcValue, name, namespace, t) {
  var retVal = bcValue > pcValue ? t("".concat(namespace, ".diagnostic.").concat(name, ".", 'plusLow')) : t("".concat(namespace, ".diagnostic.").concat(name, ".", 'minusLow'));
  return retVal;
};

var fitDescriptionText = function fitDescriptionText(fit, name, namespace, itemMissingInJoboffer, itemMissingInProfile, bcValue, pcValue, t) {
  var thisFit = getFit(fit, itemMissingInProfile, itemMissingInJoboffer);
  return itemMissingInJoboffer ? t("".concat(namespace, ".diagnostic.").concat(name, ".empty")) : itemMissingInProfile ? t("".concat(namespace, ".diagnostic.").concat(name, ".default")) : thisFit !== 'low' ? t("".concat(namespace, ".diagnostic.").concat(name, ".").concat(thisFit)) : t("".concat(namespace, ".diagnostic.").concat(name, ".").concat(thisFit)) + ' ' + getLowFitText(bcValue, pcValue, name, namespace, t);
};

exports.fitDescriptionText = fitDescriptionText;