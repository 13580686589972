"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePersistentReducer = exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var usePersistentReducer = function usePersistentReducer(reducer, initialState, key, config) {
  var getPersistedState = function getPersistedState() {
    var profileFinished = typeof window !== 'undefined' ? localStorage.getItem('pc-profile-finished') : false;

    if (config.recoverConfig && config.recoverConfig.mode === 'URL' && !profileFinished) {
      // Read Retargeting flag from localStorage
      var urlParams = {};
      var param = config.recoverConfig.param;

      try {
        urlParams = JSON.parse(localStorage.getItem('urlParams')).params;
      } catch (error) {} // If the configured parameter is set, we load the persisted data from the local storage instead of the session storage


      var paramData = urlParams[param] ? urlParams[param] : false;
      var shouldRehydrate = paramData === '1';

      try {
        var persistedStateJson = shouldRehydrate ? localStorage.getItem(key) : sessionStorage.getItem(key);
        return persistedStateJson ? JSON.parse(persistedStateJson) : initialState;
      } catch (error) {
        return initialState;
      }
    } else {
      // Default situation processing
      try {
        var _persistedStateJson = sessionStorage.getItem(key);

        return _persistedStateJson ? JSON.parse(_persistedStateJson) : initialState;
      } catch (error) {
        return initialState;
      }
    }
  };

  var _useReducer = (0, _react.useReducer)(reducer, getPersistedState()),
      _useReducer2 = (0, _slicedToArray2.default)(_useReducer, 2),
      data = _useReducer2[0],
      dispatch = _useReducer2[1];

  var persistState = (0, _react.useCallback)(function (data) {
    if (key) {
      try {
        sessionStorage.setItem(key, JSON.stringify(data));
        localStorage.setItem(key, JSON.stringify(data));
      } catch (error) {
        console.error(error);
        sessionStorage.removeItem(key);
      }
    }
  }, [key]);

  var cleanup = function cleanup() {
    var all = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    sessionStorage.removeItem(key);

    if (all) {
      localStorage.removeItem(key);
    }
  };

  (0, _react.useEffect)(function () {
    persistState(data);
  }, [data, persistState]);
  return [data, dispatch, cleanup];
};

exports.usePersistentReducer = usePersistentReducer;
var _default = usePersistentReducer;
exports.default = _default;