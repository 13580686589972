"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.supportedBrowserLanguages = exports.ourLanguages = exports.languages = exports.ifWindow = exports.getLocaleFromUrl = exports.getCustomLocale = exports.getCurrentUrl = exports.defaultLang = exports.BROWSER_LANG = void 0;

var ifWindow = function ifWindow() {
  return typeof window !== 'undefined' && navigator.userAgent !== 'ReactSnap';
};

exports.ifWindow = ifWindow;
var defaultLang = 'de';
exports.defaultLang = defaultLang;
var BROWSER_LANG = 'BROWSER_LANG';
exports.BROWSER_LANG = BROWSER_LANG;
var ourLanguages = ['de', 'pl', 'at'];
exports.ourLanguages = ourLanguages;
var getCustomLocale = ifWindow() ? {
  'custom:locale': localStorage.getItem(BROWSER_LANG) !== null ? localStorage.getItem(BROWSER_LANG) : window.location.pathname.substring(1, 3) || 'de'
} : undefined;
exports.getCustomLocale = getCustomLocale;
var supportedBrowserLanguages = ifWindow() ? (window.navigator.languages ? window.navigator.languages : [window.navigator.language]).some(function (lang) {
  if (ourLanguages.includes(lang.substring(0, 2))) {
    return true;
  }

  return false;
}) : false;
exports.supportedBrowserLanguages = supportedBrowserLanguages;

var getLocaleFromUrl = function getLocaleFromUrl() {
  return ifWindow() ? window.location.pathname.substring(1, 3) !== '' ? window.location.pathname.substring(1, 3) : defaultLang : defaultLang;
};

exports.getLocaleFromUrl = getLocaleFromUrl;

var getCurrentUrl = function getCurrentUrl() {
  return ifWindow() && window.location.pathname;
};

exports.getCurrentUrl = getCurrentUrl;
var languages = [{
  value: 'de',
  valueText: 'Deutsch',
  image: require('./img/de.svg'),
  headline: 'Willkommen',
  text: 'Aktuell gibt es ###### auf Deutsch und Polnisch. Bitte wähle deine bevorzugte Sprache.'
}, {
  value: 'pl',
  valueText: 'Polski',
  image: require('./img/pl.svg'),
  headline: 'Witamy',
  text: '###### jest obecnie dostępny w języku niemieckim i polskim. Proszę wybrać preferowany język.'
}];
exports.languages = languages;