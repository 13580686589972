"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _care = _interopRequireDefault(require("./config/health/care"));

var _truck = _interopRequireDefault(require("./config/logistics/truck"));

var _gastro = _interopRequireDefault(require("./config/service/gastro"));

var _scheduler = _interopRequireDefault(require("./config/logistics/scheduler"));

var configs = {
  A: {
    care: _care.default,
    truck: _truck.default,
    'service-gastro': _gastro.default,
    'logistics-scheduler': _scheduler.default
  },
  B: {
    truck: _truck.default,
    'service-gastro': _gastro.default,
    'logistics-scheduler': _scheduler.default,
    // default config for B variant
    care: _care.default
  }
};

var _default = function _default(discriminator) {
  var variant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'A';
  return function (target) {
    return configs[variant][discriminator][target];
  };
};

exports.default = _default;