"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commonComponentConfigurations = exports.commonCallableComponentConfigurations = exports.MULTIPLE_ANSWERS = void 0;

var _constants = require("@jobmatchme/ui/es/constants");

var MIN_AGE = 16,
    MAX_AGE = 91,
    maxYear = new Date().getFullYear() - MIN_AGE,
    minYear = new Date().getFullYear() - MAX_AGE;
var profilePrefix = 'profile-properties:common.';
var MULTIPLE_ANSWERS = 'profile-components:common.multipleAnswersHint';
exports.MULTIPLE_ANSWERS = MULTIPLE_ANSWERS;

var languages = function languages(_ref) {
  var _ref$mandatory = _ref.mandatory,
      mandatory = _ref$mandatory === void 0 ? false : _ref$mandatory,
      _ref$unsetOption = _ref.unsetOption,
      unsetOption = _ref$unsetOption === void 0 ? false : _ref$unsetOption;
  return {
    component: 'MultipleChoice',
    valueType: 'LanguagesArray',
    valueName: 'languages',
    defaultValue: [{
      name: 'GERMAN',
      skillLevel: 'NONE'
    }, {
      name: 'POLISH',
      skillLevel: 'NONE'
    }, {
      name: 'ENGLISH',
      skillLevel: 'NONE'
    }],
    options: {
      GERMAN: profilePrefix + 'languages.GERMAN',
      POLISH: profilePrefix + 'languages.POLISH',
      ENGLISH: profilePrefix + 'languages.ENGLISH'
    },
    icon: 'Business',
    mandatory: mandatory,
    unsetOption: unsetOption,
    translations: {
      label: 'common.languages.label',
      text: 'common.languages.text',
      hint: 'common.languages.hint',
      unsetOption: 'common.languages.unsetOption'
    },
    toParamType: function toParamType(key) {
      return {
        name: key,
        skillLevel: 'A'
      };
    },
    fromParamType: function fromParamType(param) {
      return param.skillLevel === 'NONE' ? undefined : param.name;
    }
  };
};

var commonCallableComponentConfigurations = {
  salary: function salary(_ref2) {
    var translations = _ref2.translations,
        defaultValue = _ref2.defaultValue,
        range = _ref2.range;
    return {
      component: 'RangeSlider',
      valueType: 'NumberRange',
      valueName: 'salary',
      defaultValue: defaultValue,
      range: range,
      stepSize: 50,
      sliderButtons: ['minimum', 'maximum'],
      icon: 'MonetizationOn',
      mandatory: false,
      translations: translations,
      educationTooltip: {
        content: 'common.salary.info',
        target: 'salary',
        offset: 10,
        position: 3,
        placement: 'auto',
        visibleFor: 'bc'
      }
    };
  },
  location: function location(_ref3) {
    var translationPrefix = _ref3.translationPrefix,
        _ref3$withRadius = _ref3.withRadius,
        withRadius = _ref3$withRadius === void 0 ? true : _ref3$withRadius,
        _ref3$maximumRadius = _ref3.maximumRadius,
        maximumRadius = _ref3$maximumRadius === void 0 ? 20 : _ref3$maximumRadius;
    return {
      component: 'Location',
      valueType: 'LocationType',
      valueName: 'location',
      withRadius: withRadius,
      defaultValue: {
        latitude: '0',
        longitude: '0',
        name: '',
        radius: {
          minimum: 5,
          maximum: maximumRadius
        } // the materialui slider maximum is 100 our maximum: 20, is the default

      },
      icon: 'LocationOn',
      mandatory: true,
      translations: {
        label: "".concat(translationPrefix, ".location.label"),
        hint: "".concat(translationPrefix, ".location.hint"),
        text: "".concat(translationPrefix, ".location.text"),
        inputText: "".concat(translationPrefix, ".location.inputText"),
        inputField: "".concat(translationPrefix, ".location.inputField")
      },
      educationTooltip: {
        content: 'common.location.info',
        target: 'location',
        offset: 10,
        position: 2,
        placement: 'auto',
        visibleFor: 'bc'
      }
    };
  },
  wizardLocation: function wizardLocation(_ref4) {
    var translationPrefix = _ref4.translationPrefix,
        _ref4$withRadius = _ref4.withRadius,
        withRadius = _ref4$withRadius === void 0 ? true : _ref4$withRadius,
        _ref4$maximumRadius = _ref4.maximumRadius,
        maximumRadius = _ref4$maximumRadius === void 0 ? 20 : _ref4$maximumRadius;
    return {
      component: 'Location',
      valueType: 'LocationType',
      valueName: 'location',
      withRadius: withRadius,
      defaultValue: {
        latitude: '0',
        longitude: '0',
        name: '',
        radius: {
          minimum: 5,
          maximum: maximumRadius
        } // the materialui slider maximum is 100 our maximum: 20, is the default

      },
      icon: 'LocationOn',
      mandatory: true,
      translations: {
        label: "".concat(translationPrefix, ".wizardLocation.label"),
        hint: "".concat(translationPrefix, ".wizardLocation.hint"),
        text: "".concat(translationPrefix, ".wizardLocation.text"),
        inputText: "".concat(translationPrefix, ".wizardLocation.inputText"),
        inputField: "".concat(translationPrefix, ".wizardLocation.inputField")
      },
      educationTooltip: {
        content: 'common.wizardLocation.info',
        target: 'wizardLocation',
        offset: 10,
        position: 2,
        placement: 'auto',
        visibleFor: 'bc'
      }
    };
  },
  worktimes: function worktimes(_ref5) {
    var translationPrefix = _ref5.translationPrefix;
    return {
      component: 'MultipleChoice',
      valueType: 'Array',
      valueName: 'worktimes',
      defaultValue: [],
      options: {
        MondayFriday: profilePrefix + 'worktimes.MondayFriday',
        Saturday: profilePrefix + 'worktimes.Saturday',
        Sunday: profilePrefix + 'worktimes.Sunday',
        Shift: profilePrefix + 'worktimes.Shift',
        Night: profilePrefix + 'worktimes.Night'
      },
      mandatory: false,
      translations: {
        text: "".concat(translationPrefix, ".worktimes.text"),
        hint: MULTIPLE_ANSWERS,
        label: "".concat(translationPrefix, ".worktimes.label")
      }
    };
  },
  languages: languages,
  workExperience: function workExperience(_ref6) {
    var translationPrefix = _ref6.translationPrefix,
        _ref6$defaultValue = _ref6.defaultValue,
        defaultValue = _ref6$defaultValue === void 0 ? null : _ref6$defaultValue;
    return {
      component: 'SingleChoice',
      valueType: 'String',
      valueName: 'workExperience',
      defaultValue: defaultValue,
      options: {
        NONE: profilePrefix + 'workExperience.NONE',
        ONE_TO_FIVE_YEARS: profilePrefix + 'workExperience.ONE_TO_FIVE_YEARS',
        FIVE_TO_TEN_YEARS: profilePrefix + 'workExperience.FIVE_TO_TEN_YEARS',
        MORE_THAN_TEN_YEARS: profilePrefix + 'workExperience.MORE_THAN_TEN_YEARS'
      },
      icon: 'Update',
      mandatory: false,
      translations: {
        label: "".concat(translationPrefix, ".workExperience.label"),
        text: "".concat(translationPrefix, ".workExperience.text")
      },
      educationTooltip: {
        content: "".concat(translationPrefix, ".workExperience.info"),
        target: 'workExperience',
        offset: 10,
        position: 12,
        placement: 'auto',
        visibleFor: 'bc'
      }
    };
  }
};
exports.commonCallableComponentConfigurations = commonCallableComponentConfigurations;

var generateObject = function generateObject(variant) {
  return {
    component: 'MultipleInputs',
    valueType: 'DescriptionString',
    valueName: 'registrationData',
    identifier: 'userdata',
    defaultValue: {},
    icon: '',
    mandatory: true,
    translations: {
      label: 'common.wizardBcRegistration.label',
      hint: 'common.wizardBcRegistration.hint',
      text: 'common.wizardBcRegistration.text'
    },
    translationOptions: {
      duration: _constants.FREE_TRIAL_DURATION
    },
    help: {
      info1: "common.".concat(variant, ".info1"),
      info2: "common.".concat(variant, ".info2"),
      info3: "common.".concat(variant, ".info3"),
      info4: "common.".concat(variant, ".info4")
    },
    form: {
      email: {
        translation: 'common.wizardBcRegistration.email',
        error: 'common.wizardBcRegistration.Error.email',
        empty: 'common.wizardBcRegistration.Error.emptyEmail',
        exists: 'common.wizardBcRegistration.Error.emailExists',
        mandatory: true,
        type: 'email'
      },
      phone: {
        mandatory: true,
        type: 'tel',
        translation: 'common.wizardBcRegistration.phone',
        max: 'common.wizardBcRegistration.Error.helperMax',
        min: 'common.wizardBcRegistration.Error.helperMin',
        error: 'common.wizardBcRegistration.Error.phone',
        exists: 'common.wizardBcRegistration.Error.phoneExists'
      },
      password: {
        translation: 'common.wizardBcRegistration.password',
        error: 'common.wizardBcRegistration.Error.password',
        empty: 'common.wizardBcRegistration.Error.emptyPassword',
        mandatory: true,
        exists: '',
        type: 'password'
      },
      previewButton: {
        translation: 'common.wizardBcRegistration.previewButton',
        color: 'dark',
        mandatory: false,
        action: 'preview',
        variant: 'outlined',
        width: '4',
        type: 'Button'
      },
      button: {
        translation: "common.".concat(variant, ".button"),
        type: 'Button',
        color: 'dark',
        mandatory: true,
        action: 'next',
        width: '8'
      },
      modalDialog: {
        title: 'common.wizardBcRegistration.dialog.title',
        btn: 'common.wizardBcRegistration.dialog.btn',
        type: 'ModalDialog',
        color: 'dark',
        action: 'preview',
        width: '4',
        pcData: {
          matchingScores: {
            training: 1
          },
          diagnostic: {
            itemsFit: [{
              name: 'AN1',
              fit: 1,
              itemMissingInProfile: false,
              itemMissingInJoboffer: false,
              pcValue: 25,
              bcValue: 45
            }, {
              fit: 0.89,
              name: 'UN1',
              itemMissingInProfile: false,
              itemMissingInJoboffer: false,
              pcValue: 35,
              bcValue: 45
            }, {
              name: 'AU1',
              fit: 0.64,
              itemMissingInProfile: false,
              itemMissingInJoboffer: false,
              pcValue: 45,
              bcValue: 25
            }, {
              name: 'WM1',
              fit: 0.8,
              itemMissingInProfile: false,
              itemMissingInJoboffer: false,
              pcValue: 25,
              bcValue: 45
            }, {
              name: 'FL1',
              fit: 0.3,
              itemMissingInProfile: false,
              itemMissingInJoboffer: false,
              pcValue: 15,
              bcValue: 45
            }]
          }
        }
      }
    }
  };
};

var commonComponentConfigurations = {
  worktimes: commonCallableComponentConfigurations.worktimes({
    translationPrefix: 'common'
  }),
  location: commonCallableComponentConfigurations.location({
    translationPrefix: 'common',
    withRadius: true
  }),
  locationWithoutRadius: commonCallableComponentConfigurations.location({
    translationPrefix: 'common',
    withRadius: false
  }),
  wizardLocationWithoutRadius: commonCallableComponentConfigurations.location({
    translationPrefix: 'common',
    withRadius: false
  }),
  wizardBcRegistration: generateObject('wizardBcRegistration'),
  wizardBcDirectRegistration: generateObject('wizardBcDirectRegistration'),
  birthYear: {
    component: 'DropDown',
    valueType: 'Number',
    valueName: 'birthYear',
    defaultValue: null,
    numberRange: {
      minimum: minYear,
      maximum: maxYear,
      order: 'descending'
    },
    mandatory: false,
    translations: {
      text: 'common.birthYear.text',
      selectPlaceholderText: 'common.birthYear.selectPlaceholderText',
      label: 'common.birthYear.label'
    }
  },
  availabilityDate: {
    component: 'DateSelect',
    valueType: 'Number',
    valueName: 'availabilityDate',
    icon: 'Today',
    defaultValue: Date.now(),
    mandatory: false,
    translations: {
      label: 'common.availabilityDate.label',
      text: 'common.availabilityDate.text'
    }
  },
  additionalBenefits: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'additionalBenefits',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Grade',
    mandatory: false,
    translations: {
      label: 'common.additionalBenefits.label',
      text: 'common.additionalBenefits.text',
      inputField: 'common.additionalBenefits.inputField',
      hint: 'common.additionalBenefits.hint'
    }
  },
  companyCulture: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'companyCulture',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Help',
    mandatory: false,
    translations: {
      label: 'common.companyCulture.label',
      text: 'common.companyCulture.text',
      inputField: 'common.companyCulture.inputField'
    }
  },
  other: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'other',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Help',
    mandatory: false,
    translations: {
      label: 'common.other.label',
      text: 'common.other.text',
      inputField: 'common.other.inputField'
    }
  },
  wizardCompanyCulture: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'companyCulture',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Help',
    mandatory: false,
    translations: {
      label: 'common.wizardCompanyCulture.label',
      text: 'common.wizardCompanyCulture.text',
      inputField: 'common.wizardCompanyCulture.inputField'
    }
  },
  externalName: {
    component: 'LongText',
    valueType: 'DescriptionString',
    valueName: 'names',
    identifier: 'matching',
    defaultValue: {},
    icon: 'Help',
    specialDefault: true,
    mandatory: false,
    resettable: true,
    translations: {
      label: 'common.externalName.label',
      text: 'common.externalName.text',
      hint: 'common.externalName.hint'
    }
  },
  generatedExternalName: {
    component: 'LongText',
    valueType: 'DescriptionString',
    valueName: 'names',
    identifier: 'matching',
    defaultValue: {},
    icon: 'Help',
    specialDefault: false,
    generatedText: true,
    mandatory: false,
    resettable: true,
    translations: {
      label: 'common.wizardExternalName.label',
      text: 'common.wizardExternalName.text',
      hint: 'common.wizardExternalName.hint',
      inputField: 'common.wizardExternalName.inputField',
      care: 'common.wizardExternalName.careJob',
      truck: 'common.wizardExternalName.truckJob',
      'service-gastro': 'common.wizardExternalName.gastroJob',
      'logistics-scheduler': 'common.wizardExternalName.schedulerJob'
    },
    educationTooltip: {
      content: 'common.wizardExternalName.info',
      target: 'diagnostic-wizardExternalName',
      offset: 10,
      position: 1,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  bcName: {
    component: 'LongText',
    valueType: 'DescriptionString',
    valueName: 'registrationData',
    identifier: 'bcName',
    defaultValue: {},
    icon: 'Help',
    specialDefault: true,
    mandatory: false,
    resettable: true,
    translations: {
      label: 'common.bcName.label',
      text: 'common.bcName.text',
      hint: 'common.bcName.hint',
      inputField: 'common.bcName.inputField'
    },
    educationTooltip: {
      content: 'common.bcName.info',
      target: 'bcName',
      offset: 10,
      position: 3,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  internalName: {
    component: 'LongText',
    valueType: 'DescriptionString',
    valueName: 'names',
    identifier: 'bcDashboard',
    defaultValue: {},
    icon: 'Help',
    specialDefault: true,
    mandatory: false,
    resettable: true,
    translations: {
      label: 'common.internalName.label',
      text: 'common.internalName.text',
      hint: 'common.internalName.hint'
    }
  },
  // Diagnostics
  AN1: {
    component: 'DiagnosticSlider',
    valueType: 'DiagnosticArray',
    valueName: 'diagnostic',
    defaultValue: [],
    identifier: 'AN1',
    position: 1,
    minimumValue: -50,
    maximumValue: 50,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.AN1.label',
      text: 'common.AN1.text',
      minimumValueText: 'common.AN1.minimumValueText',
      maximumValueText: 'common.AN1.maximumValueText'
    },
    educationTooltip: {
      content: 'common.AN1.tooltipContent',
      target: 'diagnostic-AN1',
      delay: 5000,
      offset: 10,
      placement: 'auto',
      visibleFor: 'pc'
    }
  },
  UN1: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'UN1',
    position: 2,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.UN1.label',
      text: 'common.UN1.text',
      minimumValueText: 'common.UN1.minimumValueText',
      maximumValueText: 'common.UN1.maximumValueText'
    },
    educationTooltip: {
      content: 'common.AN1.tooltipContent',
      target: 'diagnostic-AN1',
      delay: 5000,
      offset: 10,
      placement: 'auto',
      visibleFor: 'pc'
    }
  },
  AU1: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'AU1',
    position: 3,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.AU1.label',
      text: 'common.AU1.text',
      minimumValueText: 'common.AU1.minimumValueText',
      maximumValueText: 'common.AU1.maximumValueText'
    }
  },
  WM1: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'WM1',
    position: 4,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.WM1.label',
      text: 'common.WM1.text',
      minimumValueText: 'common.WM1.minimumValueText',
      maximumValueText: 'common.WM1.maximumValueText'
    }
  },
  FL1: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'FL1',
    position: 5,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.FL1.label',
      text: 'common.FL1.text',
      minimumValueText: 'common.FL1.minimumValueText',
      maximumValueText: 'common.FL1.maximumValueText'
    }
  },
  GE11: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'GE11',
    position: 6,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.GE11.label',
      text: 'common.GE11.text',
      minimumValueText: 'common.GE11.minimumValueText',
      maximumValueText: 'common.GE11.maximumValueText'
    }
  },
  GE12: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'GE12',
    position: 7,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.GE12.label',
      text: 'common.GE12.text',
      minimumValueText: 'common.GE12.minimumValueText',
      maximumValueText: 'common.GE12.maximumValueText'
    }
  },
  EX11: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'EX11',
    position: 8,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.EX11.label',
      text: 'common.EX11.text',
      minimumValueText: 'common.EX11.minimumValueText',
      maximumValueText: 'common.EX11.maximumValueText'
    }
  },
  EX12: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'EX12',
    position: 9,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.EX12.label',
      text: 'common.EX12.text',
      minimumValueText: 'common.EX12.minimumValueText',
      maximumValueText: 'common.EX12.maximumValueText'
    }
  },
  AZ1: {
    component: 'DiagnosticSlider',
    valueName: 'diagnostic',
    valueType: 'DiagnosticArray',
    defaultValue: [],
    minimumValue: -50,
    maximumValue: 50,
    identifier: 'AZ1',
    position: 10,
    initialValue: 0,
    mandatory: false,
    translations: {
      label: 'common.AZ1.label',
      text: 'common.AZ1.text',
      minimumValueText: 'common.AZ1.minimumValueText',
      maximumValueText: 'common.AZ1.maximumValueText'
    }
  },
  // Personal information of the PC registration
  name: {
    component: 'SingleLineText',
    valueName: 'name',
    valueType: 'String',
    inputType: 'text',
    defaultValue: null,
    mandatory: true,
    translations: {
      text: 'registration.name.text',
      hint: 'registration.name.hint',
      inputField: 'registration.name.inputField',
      defaultSelectorText: 'registration.name.defaultSelectorText',
      helper: 'registration.name.helper'
    },
    selectBox: {
      valueName: 'gender',
      valueType: 'String',
      defaultValue: 'mr',
      options: {
        mr: 'registration.name.gender.mr',
        mrs: 'registration.name.gender.mrs',
        diverse: 'registration.name.gender.diverse'
      }
    }
  },
  nameSimplified: {
    component: 'SingleLineText',
    valueName: 'name',
    valueType: 'String',
    inputType: 'text',
    defaultValue: null,
    mandatory: true,
    translations: {
      text: 'registration.name.simplified_text',
      hint: 'registration.name.simplified_hint',
      inputField: 'registration.name.inputField',
      defaultSelectorText: 'registration.name.defaultSelectorText',
      helper: 'registration.name.helper'
    },
    selectBox: {
      valueName: 'gender',
      valueType: 'String',
      defaultValue: 'mr',
      options: {
        mr: 'registration.name.gender.mr',
        mrs: 'registration.name.gender.mrs',
        diverse: 'registration.name.gender.diverse'
      }
    }
  },
  phone: {
    component: 'SinglePhone',
    hintComponent: 'phoneHint',
    valueName: 'phone',
    valueType: 'String',
    inputType: 'tel',
    defaultValue: null,
    mandatory: true,
    translations: {
      text: 'registration.phone.text',
      inputField: 'registration.phone.inputField',
      hint: 'registration.phone.hint',
      helper: 'registration.phone.helper',
      helperMin: 'registration.phone.helperMin',
      helperMax: 'registration.phone.helperMax'
    }
  },
  phoneSimplified: {
    component: 'SinglePhone',
    hintComponent: 'phoneHint',
    valueName: 'phone',
    valueType: 'String',
    inputType: 'tel',
    defaultValue: null,
    mandatory: true,
    translations: {
      text: 'registration.phone.text',
      hint: 'registration.phone.hint',
      inputField: 'registration.phone.inputField',
      helper: 'registration.phone.helper',
      helperMin: 'registration.phone.helperMin',
      helperMax: 'registration.phone.helperMax'
    }
  },
  email: {
    component: 'SignUp',
    valueName: 'email',
    valueType: 'String',
    inputType: 'email',
    defaultValue: null,
    mandatory: true,
    variant: '',
    translations: {
      text: 'registration.email.text',
      submit: 'registration.email.submit',
      inputField: 'registration.email.inputField',
      passwordField: 'registration.email.passwordField',
      passwordHelper: 'registration.email.passwordHelper',
      emailHelper: 'registration.email.emailHelper',
      privacyFirst: 'registration.email.privacyFirst',
      privacyLink: 'registration.email.privacyLink',
      privacyLast: 'registration.email.privacyLast',
      seperatorText: 'registration.email.seperatorText',
      emailExists: 'registration.email.emailExists'
    },
    hideNavigation: true
  },
  emailSimplified: {
    component: 'SignUp',
    valueName: 'email',
    valueType: 'String',
    inputType: 'email',
    defaultValue: null,
    mandatory: true,
    variant: '',
    translations: {
      text: 'registration.email.simplified_text',
      submit: 'registration.email.simplified_submit',
      inputField: 'registration.email.inputField',
      passwordField: 'registration.email.passwordField',
      passwordHelper: 'registration.email.passwordHelper',
      emailHelper: 'registration.email.emailHelper',
      privacyFirst: 'registration.email.simplified_privacyFirst',
      privacyLink: 'registration.email.privacyLink',
      privacyLast: 'registration.email.privacyLast',
      seperatorText: 'registration.email.seperatorText',
      emailExists: 'registration.email.emailExists'
    },
    hideNavigation: true
  }
};
exports.commonComponentConfigurations = commonComponentConfigurations;