"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isVerticalType = isVerticalType;

function isVerticalType(value) {
  var allowedKeys = ["care", "truck", "logistics-scheduler", "service-gastro"];
  return allowedKeys.indexOf(value) !== -1;
}