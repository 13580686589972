"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.supportedLocales = exports.internalRoutes = exports.internalLocalizedRoutes = exports.externalStaticRoutes = exports.externalRoutes = exports.default = exports.RouteContext = exports.RouteActionsContext = void 0;

var _Internal = _interopRequireDefault(require("@jobmatchme/routing/es/provider/Internal"));

var supportedLocales = ['de', 'at', 'pl'];
exports.supportedLocales = supportedLocales;
var externalRoutes = ['dashboard', 'home', 'pcmatching', 'pcforgotpwd', 'pcLogin', 'pcoptin', 'pcOptoutSettings', 'pcOptoutSettingsNoToken'];
exports.externalRoutes = externalRoutes;
var externalStaticRoutes = ['home'];
exports.externalStaticRoutes = externalStaticRoutes;
var internalRoutes = ['appHome', 'appHomeRegistered', 'returned', 'likesigned', 'likedirect', 'profile', 'jobs', 'search', 'vertical', 'verticalCare', 'verticalTruck', 'verticalLogisticsScheduler', 'verticalServiceGastro'];
exports.internalRoutes = internalRoutes;
var internalLocalizedRoutes = {
  appHome: '',
  appHomeRegistered: 'home/success/sign-up/:discriminator/',
  search: '',
  returned: 'returned/',
  likesigned: 'emailconversion/',
  likedirect: 'like/*',
  profile: {
    de: 'einstellungen/',
    at: 'einstellungen/',
    pl: 'ustawienia/'
  },
  jobs: 'bewerbungen/',
  vertical: 'neue-suche/',
  verticalTruck: 'neue-suche/fahrer/*',
  verticalCare: 'neue-suche/pfleger/*',
  verticalLogisticsScheduler: 'neue-suche/disponent/*',
  verticalServiceGastro: 'neue-suche/service/*'
};
exports.internalLocalizedRoutes = internalLocalizedRoutes;
var router = (0, _Internal.default)('pcmatching', internalLocalizedRoutes, externalRoutes, externalStaticRoutes);
var RouteContext = router.context;
exports.RouteContext = RouteContext;
var RouteActionsContext = router.actions;
exports.RouteActionsContext = RouteActionsContext;
var _default = router.provider;
exports.default = _default;