"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pc = exports.default = exports.bc = void 0;

var _common = require("../common");

var birthYear = _common.commonComponentConfigurations.birthYear,
    availabilityDate = _common.commonComponentConfigurations.availabilityDate,
    additionalBenefits = _common.commonComponentConfigurations.additionalBenefits,
    companyCulture = _common.commonComponentConfigurations.companyCulture,
    bcName = _common.commonComponentConfigurations.bcName,
    nameEditing = _common.commonComponentConfigurations.nameEditing,
    externalName = _common.commonComponentConfigurations.externalName,
    generatedExternalName = _common.commonComponentConfigurations.generatedExternalName,
    wizardBcRegistration = _common.commonComponentConfigurations.wizardBcRegistration,
    wizardBcDirectRegistration = _common.commonComponentConfigurations.wizardBcDirectRegistration,
    internalName = _common.commonComponentConfigurations.internalName,
    AN1 = _common.commonComponentConfigurations.AN1,
    UN1 = _common.commonComponentConfigurations.UN1,
    AU1 = _common.commonComponentConfigurations.AU1,
    WM1 = _common.commonComponentConfigurations.WM1,
    FL1 = _common.commonComponentConfigurations.FL1,
    GE11 = _common.commonComponentConfigurations.GE11,
    GE12 = _common.commonComponentConfigurations.GE12,
    EX11 = _common.commonComponentConfigurations.EX11,
    EX12 = _common.commonComponentConfigurations.EX12,
    AZ1 = _common.commonComponentConfigurations.AZ1,
    name = _common.commonComponentConfigurations.name,
    phone = _common.commonComponentConfigurations.phone,
    email = _common.commonComponentConfigurations.email,
    nameSimplified = _common.commonComponentConfigurations.nameSimplified,
    phoneSimplified = _common.commonComponentConfigurations.phoneSimplified,
    emailSimplified = _common.commonComponentConfigurations.emailSimplified;
var salary = _common.commonCallableComponentConfigurations.salary,
    location = _common.commonCallableComponentConfigurations.location,
    wizardLocation = _common.commonCallableComponentConfigurations.wizardLocation,
    workExperience = _common.commonCallableComponentConfigurations.workExperience,
    languages = _common.commonCallableComponentConfigurations.languages;
var profilePrefix = 'profile-properties:care.';
var componentConfigurations = {
  location: location({
    translationPrefix: 'care',
    withRadius: true,
    maximumRadius: 50
  }),
  locationWithoutRadius: location({
    translationPrefix: 'care',
    withRadius: false
  }),
  wizardLocationWithoutRadius: wizardLocation({
    translationPrefix: 'common',
    withRadius: false
  }),
  workTypes: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'workTypes',
    defaultValue: [],
    options: {
      NURSING: profilePrefix + 'workTypes.NURSING',
      PEDIATRIC_NURSING: profilePrefix + 'workTypes.PEDIATRIC_NURSING',
      ELDERLY_CARE: profilePrefix + 'workTypes.ELDERLY_CARE',
      OTHER: profilePrefix + 'workTypes.OTHER'
    },
    icon: 'Business',
    mandatory: false,
    translations: {
      label: 'care.workTypes.label',
      text: 'care.workTypes.text',
      hint: _common.MULTIPLE_ANSWERS
    }
  },
  workPosition: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'workPosition',
    defaultValue: [],
    options: {
      APPRENTICESHIP: profilePrefix + 'workPosition.APPRENTICESHIP',
      EMPLOYEE: profilePrefix + 'workPosition.EMPLOYEE',
      LEAD: profilePrefix + 'workPosition.LEAD'
    },
    icon: 'People',
    mandatory: false,
    translations: {
      label: 'care.workPosition.label',
      text: 'care.workPosition.text',
      hint: _common.MULTIPLE_ANSWERS
    }
  },
  workPlace: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'workPlace',
    defaultValue: [],
    options: {
      OUTPATIENT: profilePrefix + 'workPlace.OUTPATIENT',
      STATIONARY: profilePrefix + 'workPlace.STATIONARY'
    },
    icon: 'LocalHospital',
    mandatory: false,
    translations: {
      label: 'care.workPlace.label',
      text: 'care.workPlace.text',
      hint: _common.MULTIPLE_ANSWERS
    },
    educationTooltip: {
      content: 'care.workPlace.info',
      target: 'workPlace',
      offset: 10,
      position: 3,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  department: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'department',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'HomeWork',
    mandatory: false,
    translations: {
      label: 'care.department.label',
      text: 'care.department.text',
      inputField: 'care.department.inputField'
    }
  },
  tasks: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'tasks',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'care.tasks.label',
      text: 'care.tasks.text',
      inputField: 'care.tasks.inputField'
    }
  },
  wizardTasks: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'tasks',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'care.tasks.label',
      text: 'care.tasks.text',
      inputField: 'care.tasks.inputField',
      hint: 'common.wizardDafaultHint.hint'
    },
    educationTooltip: {
      content: 'care.tasks.info',
      target: 'wizardTasks',
      offset: 10,
      position: 5,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  workTimes: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'workTimes',
    defaultValue: [],
    options: {
      FULL_TIME: profilePrefix + 'workTimes.FULL_TIME',
      PART_TIME: profilePrefix + 'workTimes.PART_TIME'
    },
    icon: 'WatchLater',
    mandatory: false,
    translations: {
      label: 'care.workTimes.label',
      text: 'care.workTimes.text',
      hint: _common.MULTIPLE_ANSWERS
    }
  },
  training: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'training',
    defaultValue: [],
    options: {
      NONE: profilePrefix + 'training.NONE',
      NURSING_ASSISTANT: profilePrefix + 'training.NURSING_ASSISTANT',
      EXAMINED_CAREGIVER: profilePrefix + 'training.EXAMINED_CAREGIVER',
      QUALIFIED_LEAD: profilePrefix + 'training.QUALIFIED_LEAD',
      DUAL_STUDY: profilePrefix + 'training.DUAL_STUDY',
      OTHER: profilePrefix + 'training.OTHER'
    },
    icon: 'School',
    mandatory: false,
    translations: {
      label: 'care.training.label',
      text: 'care.training.text',
      hint: _common.MULTIPLE_ANSWERS
    }
  },
  workExperience: workExperience({
    translationPrefix: 'care',
    defaultValue: null
  }),
  driversLicense: {
    component: 'SingleChoice',
    valueType: 'Boolean',
    valueName: 'driversLicense',
    defaultValue: null,
    options: {
      true: profilePrefix + 'driversLicense.true',
      false: profilePrefix + 'driversLicense.false'
    },
    icon: 'DriveEta',
    mandatory: false,
    translations: {
      label: 'care.driversLicense.label',
      text: 'care.driversLicense.text'
    },
    conditions: [{
      valueName: 'workPlace',
      relation: 'CONTAINS',
      value: 'OUTPATIENT'
    }]
  },
  salary: salary({
    defaultValue: {
      minimum: 1850,
      maximum: 2450
    },
    range: {
      minimum: 450,
      maximum: 6000
    },
    translations: {
      label: 'common.salary.label',
      text: 'common.salary.text',
      hint: _common.MULTIPLE_ANSWERS,
      brutto: 'common.salary.brutto',
      currency: 'common.salary.currency'
    }
  }),
  languages: languages({}),
  optionalLanguages: languages({
    unsetOption: true
  }),
  wizardBcRegistration: wizardBcRegistration,
  wizardBcDirectRegistration: wizardBcDirectRegistration,
  additionalBenefits: additionalBenefits,
  companyCulture: companyCulture,
  birthYear: birthYear,
  availabilityDate: availabilityDate,
  externalName: externalName,
  nameEditing: nameEditing,
  internalName: internalName,
  generatedExternalName: generatedExternalName,
  bcName: bcName,
  // Diagnostics
  AN1: AN1,
  UN1: UN1,
  AU1: AU1,
  WM1: WM1,
  FL1: FL1,
  GE11: GE11,
  GE12: GE12,
  EX11: EX11,
  EX12: EX12,
  AZ1: AZ1,
  // Personal information of the PC registration
  name: name,
  phone: phone,
  email: email,
  nameSimplified: nameSimplified,
  phoneSimplified: phoneSimplified,
  emailSimplified: emailSimplified
};
var pc = {
  discriminator: 'care',
  version: '1.0.1',
  target: 'pc',
  vertical: {
    de: 'pflege',
    pl: 'opieka',
    at: 'pflege',
    en: 'care'
  },
  // Components used in this configuration
  componentConfigurations: componentConfigurations,
  translationConfiguration: {
    namespace: 'pc-funnel'
  },
  // Configuration of the Funnel
  funnel: [{
    steps: [{
      componentConfiguration: 'workTypes'
    }, {
      componentConfiguration: 'workPlace'
    }, {
      componentConfiguration: 'workTimes'
    }, {
      componentConfiguration: 'workPosition'
    }, {
      componentConfiguration: 'salary'
    }, {
      componentConfiguration: 'location'
    }, {
      componentConfiguration: 'training'
    }, {
      componentConfiguration: 'workExperience'
    }, {
      componentConfiguration: 'languages'
    }, {
      componentConfiguration: 'birthYear'
    }, {
      componentConfiguration: 'driversLicense'
    }, {
      componentConfiguration: 'availabilityDate'
    }]
  }, {
    translations: {
      text: 'common.funnelSeparator2.text',
      description: 'common.funnelSeparator2.description',
      nextButton: 'common.funnelSeparator2.nextButton'
    },
    steps: [{
      componentConfiguration: 'AN1'
    }, {
      componentConfiguration: 'UN1'
    }, {
      componentConfiguration: 'AU1'
    }, {
      componentConfiguration: 'WM1'
    }, {
      componentConfiguration: 'FL1'
    }, {
      componentConfiguration: 'AZ1'
    }]
  }],
  simplifiedFunnelQuestions: [{
    steps: []
  }, {
    translations: {
      text: 'common.funnelSeparatorSimpflified.text',
      description: 'common.funnelSeparatorSimpflified.description',
      nextButton: 'common.funnelSeparatorSimpflified.nextButton'
    },
    steps: [{
      componentConfiguration: 'workTypes'
    }, {
      componentConfiguration: 'workPlace'
    }, {
      componentConfiguration: 'workTimes'
    }, {
      componentConfiguration: 'workPosition'
    }, {
      componentConfiguration: 'salary'
    }, {
      componentConfiguration: 'location'
    }, {
      componentConfiguration: 'training'
    }, {
      componentConfiguration: 'workExperience'
    }, {
      componentConfiguration: 'languages'
    }, {
      componentConfiguration: 'birthYear'
    }, {
      componentConfiguration: 'driversLicense'
    }, {
      componentConfiguration: 'availabilityDate'
    }]
  }],
  simplifiedRegistrationForm: [{
    userAttributes: {
      phone_number: 'phone',
      name: 'name',
      gender: 'gender'
    },
    steps: [{
      componentConfiguration: 'nameSimplified'
    }, {
      componentConfiguration: 'phoneSimplified'
    }, {
      componentConfiguration: 'emailSimplified'
    }]
  }],
  registration: [{
    userAttributes: {
      phone_number: 'phone',
      name: 'name',
      gender: 'gender'
    },
    steps: [{
      componentConfiguration: 'name'
    }, {
      componentConfiguration: 'phone'
    }, {
      componentConfiguration: 'email'
    }]
  }],
  edit: [{
    key: 'subtitle1',
    type: 'subtitle',
    translations: {
      headline: 'pc-funnel:care.edit.subtitle1.headline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'workTypes'
  }, {
    type: 'item',
    componentConfiguration: 'workPlace'
  }, {
    type: 'item',
    componentConfiguration: 'workTimes'
  }, {
    type: 'item',
    componentConfiguration: 'workPosition'
  }, {
    type: 'item',
    componentConfiguration: 'languages'
  }, {
    type: 'item',
    componentConfiguration: 'salary'
  }, {
    type: 'item',
    componentConfiguration: 'location'
  }, {
    key: 'subtitle2',
    type: 'subtitle',
    translations: {
      headline: 'pc-funnel:care.edit.subtitle2.headline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'training'
  }, {
    type: 'item',
    componentConfiguration: 'driversLicense'
  }, {
    type: 'item',
    componentConfiguration: 'workExperience'
  }, {
    type: 'item',
    componentConfiguration: 'birthYear'
  }, {
    type: 'item',
    componentConfiguration: 'availabilityDate'
  }, {
    key: 'subtitle3',
    type: 'subtitle',
    translations: {
      headline: 'pc-funnel:care.edit.subtitle3.headline'
    }
  }, {
    key: 'diagnosticBlock',
    componentConfiguration: '',
    type: 'block',
    allowEmpty: true,
    translations: {
      description: 'pc-funnel:care.edit.diagnosticBlock.description'
    },
    items: [{
      steps: [{
        componentConfiguration: 'AN1'
      }, {
        componentConfiguration: 'UN1'
      }, {
        componentConfiguration: 'AU1'
      }, {
        componentConfiguration: 'WM1'
      }, {
        componentConfiguration: 'FL1'
      }, {
        componentConfiguration: 'GE11'
      }, {
        componentConfiguration: 'GE12'
      }, {
        componentConfiguration: 'EX11'
      }, {
        componentConfiguration: 'EX12'
      }, {
        componentConfiguration: 'AZ1'
      }]
    }]
  }],
  startFreeWizard: [],
  startDirectActivationWizard: [],
  view: {
    profileFields: ['training', 'workExperience', 'workPlace', 'workTimes', 'workPosition', 'languages', 'location', 'birthYear', 'driversLicense', 'salary'],
    profileListFields: ['name', 'education', 'distance', 'phone', 'email', 'date'],
    primaryAttribute: 'training',
    translatedStringFields: ['workExperience']
  }
};
exports.pc = pc;

var wizardLogic = function wizardLogic(type) {
  return [{
    steps: [{
      componentConfiguration: 'wizardLocationWithoutRadius',
      mandatory: true
    }, {
      componentConfiguration: 'workPlace',
      mandatory: false
    }]
  }, {
    translations: {
      text: 'common.funnelSeparatorFirstAudienceDone.text',
      description: 'common.funnelSeparatorFirstAudienceDone.description',
      nextButton: 'common.funnelSeparatorFirstAudienceDone.nextButton'
    },
    steps: [{
      componentConfiguration: 'additionalBenefits',
      mandatory: false
    }, {
      componentConfiguration: 'wizardTasks',
      mandatory: false
    }, {
      componentConfiguration: 'bcName',
      mandatory: true
    }, {
      componentConfiguration: 'generatedExternalName',
      mandatory: true
    }, {
      componentConfiguration: type,
      mandatory: true
    }]
  }];
};

var bc = {
  discriminator: 'care',
  version: '1.0.2',
  target: 'bc',
  // Components used in this configuration
  componentConfigurations: componentConfigurations,
  // Translation options
  translationConfiguration: {
    namespace: 'bc-dashboard'
  },
  // Configuration of the Funnel
  funnel: [{
    steps: [{
      componentConfiguration: 'locationWithoutRadius',
      mandatory: true
    }, {
      componentConfiguration: 'workTypes',
      mandatory: false
    }, {
      componentConfiguration: 'workPosition',
      mandatory: false
    }, {
      componentConfiguration: 'workPlace',
      mandatory: false
    }, {
      componentConfiguration: 'workTimes',
      mandatory: false
    }, {
      componentConfiguration: 'training',
      mandatory: false
    }, {
      componentConfiguration: 'workExperience',
      mandatory: false
    }, {
      componentConfiguration: 'optionalLanguages'
    }, {
      componentConfiguration: 'driversLicense',
      mandatory: false
    }, {
      componentConfiguration: 'salary',
      mandatory: false
    }]
  }, {
    translations: {
      text: 'common.funnelSeparatorAudienceDone.text',
      description: 'common.funnelSeparatorAudienceDone.description',
      nextButton: 'common.funnelSeparatorAudienceDone.nextButton'
    },
    steps: [{
      componentConfiguration: 'tasks',
      mandatory: false
    }, {
      componentConfiguration: 'department',
      mandatory: false
    }, {
      componentConfiguration: 'additionalBenefits',
      mandatory: false
    }, {
      componentConfiguration: 'companyCulture',
      mandatory: false
    }, {
      componentConfiguration: 'internalName',
      mandatory: false
    }, {
      componentConfiguration: 'generatedExternalName',
      mandatory: false
    }]
  }, {
    translations: {
      text: 'common.funnelSeparator1.text',
      description: 'common.funnelSeparator1.description',
      nextButton: 'common.funnelSeparator1.nextButton',
      skipButton: 'common.funnelSeparator1.skipButton'
    },
    entryRequiresProfileData: true,
    steps: [{
      componentConfiguration: 'AN1',
      mandatory: false
    }, {
      componentConfiguration: 'UN1',
      mandatory: false
    }, {
      componentConfiguration: 'AU1',
      mandatory: false
    }, {
      componentConfiguration: 'WM1',
      mandatory: false
    }, {
      componentConfiguration: 'FL1',
      mandatory: false
    }]
  }],
  startFreeWizard: wizardLogic('wizardBcRegistration'),
  startDirectActivationWizard: wizardLogic('wizardBcDirectRegistration'),
  edit: [{
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.AudienceHeadline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'locationWithoutRadius',
    disabled: function disabled(value) {
      return !!value.name && !!value.latitude && !!value.longitude;
    }
  }, {
    type: 'item',
    componentConfiguration: 'workTypes'
  }, {
    type: 'item',
    componentConfiguration: 'workPosition'
  }, {
    type: 'item',
    componentConfiguration: 'workPlace',
    disabled: function disabled(value) {
      return !value || value.length !== 0;
    }
  }, {
    type: 'item',
    componentConfiguration: 'workTimes'
  }, {
    type: 'item',
    componentConfiguration: 'training'
  }, {
    type: 'item',
    componentConfiguration: 'workExperience'
  }, {
    type: 'item',
    componentConfiguration: 'optionalLanguages'
  }, {
    type: 'item',
    componentConfiguration: 'driversLicense'
  }, {
    type: 'item',
    componentConfiguration: 'salary'
  }, {
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.AdvertHeadline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'department',
    isEmpty: function isEmpty(value) {
      return !value || !value.department;
    }
  }, {
    type: 'item',
    componentConfiguration: 'tasks',
    isEmpty: function isEmpty(value) {
      return !value || !value.tasks;
    }
  }, {
    type: 'item',
    componentConfiguration: 'additionalBenefits',
    isEmpty: function isEmpty(value) {
      return !value || !value.additionalBenefits;
    }
  }, {
    type: 'item',
    componentConfiguration: 'companyCulture',
    isEmpty: function isEmpty(value) {
      return !value || !value.companyCulture;
    }
  }, {
    type: 'item',
    componentConfiguration: 'internalName',
    isEmpty: function isEmpty() {
      return false;
    }
  }, {
    type: 'item',
    componentConfiguration: 'externalName',
    isEmpty: function isEmpty() {
      return false;
    }
  }, {
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.CultureHeadline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'AN1'
  }, {
    type: 'item',
    componentConfiguration: 'UN1'
  }, {
    type: 'item',
    componentConfiguration: 'AU1'
  }, {
    type: 'item',
    componentConfiguration: 'WM1'
  }, {
    type: 'item',
    componentConfiguration: 'FL1'
  }, {
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.SettingsHeadline',
      headlineFreemium: 'bc-dashboard:JobofferEdit.SettingsHeadlineFreemium',
      description: 'bc-dashboard:JobofferEdit.SettingsDescription'
    }
  }],
  simplifiedFunnelQuestions: [],
  view: {
    primaryAttribute: 'workPlace'
  }
};
exports.bc = bc;
var _default = {
  pc: pc,
  bc: bc
};
exports.default = _default;