"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var schedulerConfig = {
  discriminator: 'logistics-scheduler',
  global: [{
    key: 'companyName',
    backend: 'match.displayName',
    default: ''
  }, {
    key: 'bcName',
    backend: 'match.bcName',
    default: ''
  }, {
    key: 'searchLocation',
    backend: 'searchProfile.location',
    default: {
      latitude: '55',
      longitude: '10'
    }
  }, {
    key: 'jobLocation',
    backend: 'match.joboffer.location',
    default: {
      latitude: '55',
      longitude: '10'
    }
  }],
  cardLines: [{
    icon: 'location',
    backend: 'match.joboffer.location.name',
    default: ''
  }, {
    icon: 'team',
    key: 'workPosition',
    backend: 'match.joboffer.workPosition',
    default: []
  }, {
    icon: 'watchLater',
    key: 'worktimes',
    backend: 'match.joboffer.worktimes',
    default: ''
  }, {
    icon: 'monetization',
    tKey: 'monetization'
  }, {
    icon: 'favorite',
    tKey: 'favorite'
  }],
  previewLines: [{
    icon: 'monetization',
    tKey: 'monetizationApt'
  }, {
    icon: 'team',
    tKey: 'workPosition'
  }, {
    primary: true,
    icon: 'location',
    backend: 'match.joboffer.location.name',
    default: ''
  }, {
    primary: true,
    icon: 'companyCulture',
    tKey: 'favoriteApt',
    default: ''
  }, {
    primary: true,
    icon: 'dots',
    tKey: '',
    default: ''
  }],
  descriptionLines: [{
    header: {
      tKey: 'additionalBenefits',
      common: true
    },
    backend: 'match.joboffer.descriptions.additionalBenefits',
    default: ''
  }, {
    header: {
      tKey: 'tasks',
      common: true
    },
    backend: 'match.joboffer.descriptions.tasks',
    default: ''
  }, {
    header: {
      tKey: 'companyCulture',
      common: true
    },
    backend: 'match.joboffer.descriptions.companyCulture',
    default: ''
  }, {
    header: {
      tKey: 'cargo'
    },
    backend: 'match.joboffer.cargo',
    default: ''
  }, {
    header: {
      tKey: 'goods'
    },
    backend: 'match.joboffer.goods',
    default: ''
  }]
};
var _default = schedulerConfig;
exports.default = _default;