"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pc = exports.default = exports.bc = void 0;

var _common = require("../common");

var bcName = _common.commonComponentConfigurations.bcName,
    birthYear = _common.commonComponentConfigurations.birthYear,
    availabilityDate = _common.commonComponentConfigurations.availabilityDate,
    wizardBcRegistration = _common.commonComponentConfigurations.wizardBcRegistration,
    wizardBcDirectRegistration = _common.commonComponentConfigurations.wizardBcDirectRegistration,
    generatedExternalName = _common.commonComponentConfigurations.generatedExternalName,
    externalName = _common.commonComponentConfigurations.externalName,
    internalName = _common.commonComponentConfigurations.internalName,
    wizardCompanyCulture = _common.commonComponentConfigurations.wizardCompanyCulture,
    AN1 = _common.commonComponentConfigurations.AN1,
    UN1 = _common.commonComponentConfigurations.UN1,
    AU1 = _common.commonComponentConfigurations.AU1,
    WM1 = _common.commonComponentConfigurations.WM1,
    FL1 = _common.commonComponentConfigurations.FL1,
    GE11 = _common.commonComponentConfigurations.GE11,
    GE12 = _common.commonComponentConfigurations.GE12,
    EX11 = _common.commonComponentConfigurations.EX11,
    EX12 = _common.commonComponentConfigurations.EX12,
    AZ1 = _common.commonComponentConfigurations.AZ1,
    name = _common.commonComponentConfigurations.name,
    phone = _common.commonComponentConfigurations.phone,
    email = _common.commonComponentConfigurations.email,
    nameSimplified = _common.commonComponentConfigurations.nameSimplified,
    phoneSimplified = _common.commonComponentConfigurations.phoneSimplified,
    emailSimplified = _common.commonComponentConfigurations.emailSimplified;
var salary = _common.commonCallableComponentConfigurations.salary,
    location = _common.commonCallableComponentConfigurations.location,
    wizardLocation = _common.commonCallableComponentConfigurations.wizardLocation,
    languages = _common.commonCallableComponentConfigurations.languages;
var profilePrefix = 'profile-properties:truck.';
var componentConfigurations = {
  locationWithoutRadius: location({
    translationPrefix: 'common',
    withRadius: false
  }),
  wizardLocationWithoutRadius: wizardLocation({
    translationPrefix: 'common',
    withRadius: false
  }),
  transportDistances: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'transportDistances',
    defaultValue: [],
    options: {
      REGIONAL: profilePrefix + 'transportDistances.REGIONAL',
      NATIONAL: profilePrefix + 'transportDistances.NATIONAL',
      INTERNATIONAL: profilePrefix + 'transportDistances.INTERNATIONAL'
    },
    mandatory: false,
    translations: {
      text: 'truck.transportDistances.text',
      hint: _common.MULTIPLE_ANSWERS,
      label: 'truck.transportDistances.label'
    },
    educationTooltip: {
      content: 'truck.transportDistances.info',
      target: 'transportDistances',
      offset: 10,
      position: 8,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  worktimes: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'worktimes',
    defaultValue: [],
    options: {
      MondayFriday: profilePrefix + 'worktimes.MondayFriday',
      Saturday: profilePrefix + 'worktimes.Saturday',
      Sunday: profilePrefix + 'worktimes.Sunday',
      Shift: profilePrefix + 'worktimes.Shift',
      Night: profilePrefix + 'worktimes.Night'
    },
    icon: 'WatchLater',
    mandatory: false,
    translations: {
      label: 'truck.worktimes.label',
      text: 'truck.worktimes.text',
      hint: _common.MULTIPLE_ANSWERS
    }
  },
  salary: salary({
    defaultValue: {
      minimum: 1850,
      maximum: 2450
    },
    range: {
      minimum: 1550,
      maximum: 5000
    },
    translations: {
      label: 'common.salary.label',
      text: 'common.salary.text',
      hint: _common.MULTIPLE_ANSWERS,
      brutto: 'common.salary.brutto',
      currency: 'common.salary.currency'
    }
  }),
  languages: languages({}),
  optionalLanguages: languages({
    unsetOption: true
  }),
  allowances: {
    component: 'RangeSlider',
    valueType: 'NumberRange',
    valueName: 'allowances',
    defaultValue: {
      minimum: 0,
      maximum: 600
    },
    range: {
      minimum: 0,
      maximum: 2000
    },
    stepSize: 50,
    sliderButtons: ['minimum', 'maximum'],
    mandatory: false,
    translations: {
      text: 'truck.allowances.text',
      hint: 'truck.allowances.hint',
      label: 'truck.allowances.label',
      brutto: 'truck.allowances.brutto',
      currency: 'truck.allowances.currency'
    }
  },
  location: {
    component: 'Location',
    valueType: 'LocationType',
    valueName: 'location',
    withRadius: false,
    defaultValue: {},
    mandatory: true,
    translations: {
      text: 'common.location.text',
      inputText: 'truck.location.inputText',
      label: 'truck.location.label',
      hint: 'truck.location.hint'
    }
  },
  licenses: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'licenses',
    defaultValue: [],
    options: {
      B: profilePrefix + 'licenses.B',
      CE: profilePrefix + 'licenses.CE',
      C: profilePrefix + 'licenses.C',
      C1E: profilePrefix + 'licenses.C1E',
      C1: profilePrefix + 'licenses.C1',
      D: profilePrefix + 'licenses.D'
    },
    mutualOptions: {
      CE: [{
        key: 'C',
        value: true
      }, {
        key: 'C1E',
        value: true
      }, {
        key: 'C1',
        value: true
      }, {
        key: 'B',
        value: true
      }]
    },
    optionIcons: {
      CE: 'CE',
      C: 'C',
      C1E: 'C1E',
      C1: 'C1',
      B: 'B',
      D: 'D'
    },
    mandatory: false,
    translations: {
      text: 'truck.licenses.text',
      hint: _common.MULTIPLE_ANSWERS,
      label: 'truck.licenses.label'
    },
    educationTooltip: {
      content: 'truck.licenses.info',
      target: 'licenses',
      offset: 10,
      position: 5,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  additionalLicenses: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'additionalLicenses',
    defaultValue: [],
    options: {
      AdrBasic: profilePrefix + 'additionalLicenses.AdrBasic',
      AdrTank: profilePrefix + 'additionalLicenses.AdrTank',
      AdrExplosives: profilePrefix + 'additionalLicenses.AdrExplosives',
      AdrRadioactives: profilePrefix + 'additionalLicenses.AdrRadioactives',
      Crane: profilePrefix + 'additionalLicenses.Crane',
      Forklift: profilePrefix + 'additionalLicenses.Forklift'
    },
    mandatory: false,
    allowEmpty: true,
    // allow empty values eg. in multipleChoices - it can only has true as value
    translations: {
      text: 'truck.additionalLicenses.text',
      hint: _common.MULTIPLE_ANSWERS,
      label: 'truck.additionalLicenses.label'
    }
  },
  truckTypes: {
    component: 'MultipleChoice',
    valueType: 'Array',
    valueName: 'truckTypes',
    defaultValue: [],
    options: {
      TrailerTruck: profilePrefix + 'truckTypes.TrailerTruck',
      Towed: profilePrefix + 'truckTypes.Towed',
      Tank: profilePrefix + 'truckTypes.Tank',
      Cold: profilePrefix + 'truckTypes.Cold',
      Container: profilePrefix + 'truckTypes.Container',
      Silo: profilePrefix + 'truckTypes.Silo',
      Wechselbruecke: profilePrefix + 'truckTypes.Wechselbruecke',
      Sonstiges: profilePrefix + 'truckTypes.Sonstiges'
    },
    mandatory: false,
    translations: {
      text: 'truck.truckTypes.text',
      hint: _common.MULTIPLE_ANSWERS,
      label: 'truck.truckTypes.label'
    }
  },
  workExperience: {
    component: 'SingleChoice',
    valueType: 'Number',
    valueName: 'workExperience',
    defaultValue: null,
    options: {
      0: profilePrefix + 'workExperience.0',
      1: profilePrefix + 'workExperience.1',
      3: profilePrefix + 'workExperience.3',
      7: profilePrefix + 'workExperience.7',
      15: profilePrefix + 'workExperience.15',
      25: profilePrefix + 'workExperience.25'
    },
    mandatory: false,
    translations: {
      text: 'truck.workExperience.text',
      label: 'truck.workExperience.label'
    }
  },
  birthYear: birthYear,
  takeTheTruck: {
    component: 'SingleChoice',
    valueType: 'String',
    valueName: 'takeTheTruck',
    defaultValue: [],
    options: {
      YES: profilePrefix + 'takeTheTruck.YES',
      NO: profilePrefix + 'takeTheTruck.NO',
      ARRANGEABLE: profilePrefix + 'takeTheTruck.ARRANGEABLE'
    },
    mandatory: false,
    translations: {
      label: 'truck.takeTheTruck.label',
      text: 'truck.takeTheTruck.text'
    }
  },
  tasks: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'tasks',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'truck.tasks.label',
      text: 'truck.tasks.text',
      inputField: 'truck.tasks.inputField'
    }
  },
  wizardTasks: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'tasks',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'truck.tasks.label',
      text: 'truck.tasks.text',
      inputField: 'truck.tasks.inputField',
      hint: 'common.wizardDafaultHint.hint'
    },
    educationTooltip: {
      content: 'truck.tasks.info',
      target: 'wizardTasks',
      offset: 10,
      position: 9,
      placement: 'auto',
      visibleFor: 'bc'
    }
  },
  income: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'income',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'truck.income.label',
      text: 'truck.income.text',
      inputField: 'truck.income.inputField'
    }
  },
  transport: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'transport',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'truck.transport.label',
      text: 'truck.transport.text',
      inputField: 'truck.transport.inputField'
    }
  },
  comment: {
    component: 'LongText',
    valueType: 'DescriptionString',
    identifier: 'comment',
    valueName: 'descriptions',
    defaultValue: {},
    icon: 'Dns',
    mandatory: false,
    translations: {
      label: 'truck.comment.label',
      text: 'truck.comment.text',
      inputField: 'truck.comment.inputField'
    }
  },
  wizardCompanyCulture: wizardCompanyCulture,
  availabilityDate: availabilityDate,
  externalName: externalName,
  internalName: internalName,
  wizardBcRegistration: wizardBcRegistration,
  wizardBcDirectRegistration: wizardBcDirectRegistration,
  generatedExternalName: generatedExternalName,
  bcName: bcName,
  // Diagnostics
  AN1: AN1,
  UN1: UN1,
  AU1: AU1,
  WM1: WM1,
  FL1: FL1,
  GE11: GE11,
  GE12: GE12,
  EX11: EX11,
  EX12: EX12,
  AZ1: AZ1,
  // Personal information of the PC registration
  name: name,
  phone: phone,
  email: email,
  nameSimplified: nameSimplified,
  phoneSimplified: phoneSimplified,
  emailSimplified: emailSimplified
};
var pc = {
  discriminator: 'truck',
  version: '1.0.1',
  target: 'pc',
  vertical: {
    de: 'fahrer',
    at: 'fahrer',
    en: 'truck',
    pl: 'kierowca'
  },
  // Components used in this configuration
  componentConfigurations: componentConfigurations,
  translationConfiguration: {
    namespace: 'pc-funnel'
  },
  funnel: [{
    steps: [{
      componentConfiguration: 'transportDistances'
    }, {
      componentConfiguration: 'worktimes'
    }, {
      componentConfiguration: 'salary'
    }, {
      componentConfiguration: 'location'
    }, {
      componentConfiguration: 'licenses'
    }, {
      componentConfiguration: 'additionalLicenses'
    }, {
      componentConfiguration: 'truckTypes'
    }, {
      componentConfiguration: 'workExperience'
    }, {
      componentConfiguration: 'languages'
    }, {
      componentConfiguration: 'birthYear'
    }, {
      componentConfiguration: 'availabilityDate'
    }]
  }, {
    translations: {
      text: 'common.funnelSeparator2.text',
      description: 'common.funnelSeparator2.description',
      nextButton: 'common.funnelSeparator2.nextButton'
    },
    steps: [{
      componentConfiguration: 'AN1'
    }, {
      componentConfiguration: 'UN1'
    }, {
      componentConfiguration: 'AU1'
    }, {
      componentConfiguration: 'WM1'
    }, {
      componentConfiguration: 'FL1'
    }, {
      componentConfiguration: 'AZ1'
    }]
  }],
  simplifiedFunnelQuestions: [{
    steps: []
  }, {
    translations: {
      text: 'common.funnelSeparatorSimpflified.text',
      description: 'common.funnelSeparatorSimpflified.description',
      nextButton: 'common.funnelSeparatorSimpflified.nextButton'
    },
    steps: [{
      componentConfiguration: 'transportDistances'
    }, {
      componentConfiguration: 'worktimes'
    }, {
      componentConfiguration: 'salary'
    }, {
      componentConfiguration: 'location'
    }, {
      componentConfiguration: 'licenses'
    }, {
      componentConfiguration: 'additionalLicenses'
    }, {
      componentConfiguration: 'truckTypes'
    }, {
      componentConfiguration: 'workExperience'
    }, {
      componentConfiguration: 'languages'
    }, {
      componentConfiguration: 'birthYear'
    }, {
      componentConfiguration: 'availabilityDate'
    }]
  }],
  simplifiedRegistrationForm: [{
    userAttributes: {
      phone_number: 'phone',
      name: 'name',
      gender: 'gender'
    },
    steps: [{
      componentConfiguration: 'nameSimplified'
    }, {
      componentConfiguration: 'phoneSimplified'
    }, {
      componentConfiguration: 'emailSimplified'
    }]
  }],
  registration: [{
    userAttributes: {
      phone_number: 'phone',
      name: 'name',
      gender: 'gender'
    },
    steps: [{
      componentConfiguration: 'name'
    }, {
      componentConfiguration: 'phone'
    }, {
      componentConfiguration: 'email'
    }]
  }],
  edit: [{
    key: 'subtitle1',
    type: 'subtitle',
    translations: {
      headline: 'pc-funnel:truck.edit.subtitle1.headline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'transportDistances'
  }, {
    type: 'item',
    componentConfiguration: 'languages'
  }, {
    type: 'item',
    componentConfiguration: 'worktimes'
  }, {
    type: 'item',
    componentConfiguration: 'salary'
  }, {
    type: 'item',
    componentConfiguration: 'location'
  }, {
    key: 'subtitle2',
    type: 'subtitle',
    translations: {
      headline: 'pc-funnel:truck.edit.subtitle2.headline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'licenses'
  }, {
    type: 'item',
    componentConfiguration: 'additionalLicenses'
  }, {
    type: 'item',
    componentConfiguration: 'truckTypes'
  }, {
    type: 'item',
    componentConfiguration: 'workExperience'
  }, {
    type: 'item',
    componentConfiguration: 'birthYear'
  }, {
    type: 'item',
    componentConfiguration: 'availabilityDate'
  }, {
    key: 'subtitle3',
    type: 'subtitle',
    translations: {
      headline: 'pc-funnel:truck.edit.subtitle3.headline'
    }
  }, {
    key: 'diagnosticBlock',
    componentConfiguration: '',
    type: 'block',
    allowEmpty: true,
    translations: {
      description: 'pc-funnel:truck.edit.diagnosticBlock.description'
    },
    items: [{
      steps: [{
        componentConfiguration: 'AN1'
      }, {
        componentConfiguration: 'UN1'
      }, {
        componentConfiguration: 'AU1'
      }, {
        componentConfiguration: 'WM1'
      }, {
        componentConfiguration: 'FL1'
      }, {
        componentConfiguration: 'GE11'
      }, {
        componentConfiguration: 'GE12'
      }, {
        componentConfiguration: 'EX11'
      }, {
        componentConfiguration: 'EX12'
      }, {
        componentConfiguration: 'AZ1'
      }]
    }]
  }],
  startFreeWizard: [],
  startDirectActivationWizard: [],
  view: {
    profileFields: ['birthYear', 'licenses', 'transportDistances', 'additionalLicenses', 'worktimes', 'workExperience', 'salary', 'truckTypes', 'languages'],
    profileListFields: ['name', 'licenses', 'distance', 'phone', 'email', 'date'],
    primaryAttribute: 'transportDistances',
    translatedStringFields: ['workExperience', 'takeTheTruck']
  }
};
exports.pc = pc;

var wizardLogic = function wizardLogic(type) {
  return [{
    steps: [{
      componentConfiguration: 'wizardLocationWithoutRadius',
      mandatory: true
    }, {
      componentConfiguration: 'licenses',
      mandatory: false
    }, {
      componentConfiguration: 'transportDistances',
      mandatory: false
    }, {
      componentConfiguration: 'salary',
      mandatory: false
    }]
  }, {
    translations: {
      text: 'common.funnelSeparatorFirstAudienceDone.text',
      description: 'common.funnelSeparatorFirstAudienceDone.description',
      nextButton: 'common.funnelSeparatorFirstAudienceDone.nextButton'
    },
    steps: [{
      componentConfiguration: 'income',
      mandatory: false
    }, {
      componentConfiguration: 'wizardTasks',
      mandatory: false
    }, {
      componentConfiguration: 'bcName',
      mandatory: true
    }, {
      componentConfiguration: 'generatedExternalName',
      mandatory: true
    }, {
      componentConfiguration: type,
      mandatory: true
    }]
  }];
};

var bc = {
  discriminator: 'truck',
  version: '1.0.2',
  target: 'bc',
  // Components used in this configuration
  componentConfigurations: componentConfigurations,
  translationConfiguration: {
    namespace: 'bc-dashboard'
  },
  funnel: [{
    steps: [{
      componentConfiguration: 'locationWithoutRadius',
      mandatory: true
    }, {
      componentConfiguration: 'optionalLanguages'
    }, {
      componentConfiguration: 'licenses'
    }, {
      componentConfiguration: 'additionalLicenses'
    }, {
      componentConfiguration: 'workExperience'
    }, {
      componentConfiguration: 'transportDistances'
    }, {
      componentConfiguration: 'worktimes'
    }, {
      componentConfiguration: 'takeTheTruck'
    }, {
      componentConfiguration: 'salary'
    }, {
      componentConfiguration: 'allowances'
    }]
  }, {
    translations: {
      text: 'common.funnelSeparatorAudienceDone.text',
      description: 'common.funnelSeparatorAudienceDone.description',
      nextButton: 'common.funnelSeparatorAudienceDone.nextButton'
    },
    steps: [{
      componentConfiguration: 'tasks'
    }, {
      componentConfiguration: 'income'
    }, {
      componentConfiguration: 'transport'
    }, {
      componentConfiguration: 'comment'
    }, {
      componentConfiguration: 'internalName',
      mandatory: false
    }, {
      componentConfiguration: 'generatedExternalName',
      mandatory: false
    }]
  }, {
    translations: {
      text: 'common.funnelSeparator1.text',
      description: 'common.funnelSeparator1.description',
      nextButton: 'common.funnelSeparator1.nextButton',
      skipButton: 'common.funnelSeparator1.skipButton'
    },
    steps: [{
      componentConfiguration: 'AN1'
    }, {
      componentConfiguration: 'UN1'
    }, {
      componentConfiguration: 'AU1'
    }, {
      componentConfiguration: 'WM1'
    }, {
      componentConfiguration: 'FL1'
    }]
  }],
  simplifiedFunnelQuestions: [],
  startFreeWizard: wizardLogic('wizardBcRegistration'),
  startDirectActivationWizard: wizardLogic('wizardBcDirectRegistration'),
  edit: [{
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.AudienceHeadline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'locationWithoutRadius',
    disabled: function disabled(value) {
      return !!value.name && !!value.latitude && !!value.longitude;
    }
  }, {
    type: 'item',
    componentConfiguration: 'licenses'
  }, {
    type: 'item',
    componentConfiguration: 'additionalLicenses'
  }, {
    type: 'item',
    componentConfiguration: 'transportDistances'
  }, {
    type: 'item',
    componentConfiguration: 'salary'
  }, {
    type: 'item',
    componentConfiguration: 'allowances'
  }, {
    type: 'item',
    componentConfiguration: 'worktimes'
  }, {
    type: 'item',
    componentConfiguration: 'workExperience'
  }, {
    type: 'item',
    componentConfiguration: 'optionalLanguages'
  }, {
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.AdvertHeadline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'takeTheTruck'
  }, {
    type: 'item',
    componentConfiguration: 'tasks',
    isEmpty: function isEmpty(value) {
      return !value || !value.tasks;
    }
  }, {
    type: 'item',
    componentConfiguration: 'income',
    isEmpty: function isEmpty(value) {
      return !value || !value.income;
    }
  }, {
    type: 'item',
    componentConfiguration: 'transport',
    isEmpty: function isEmpty(value) {
      return !value || !value.transport;
    }
  }, {
    type: 'item',
    componentConfiguration: 'comment',
    isEmpty: function isEmpty(value) {
      return !value || !value.comment;
    }
  }, {
    type: 'item',
    componentConfiguration: 'internalName',
    isEmpty: function isEmpty() {
      return false;
    }
  }, {
    type: 'item',
    componentConfiguration: 'externalName',
    isEmpty: function isEmpty() {
      return false;
    }
  }, {
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.CultureHeadline'
    }
  }, {
    type: 'item',
    componentConfiguration: 'AN1'
  }, {
    type: 'item',
    componentConfiguration: 'UN1'
  }, {
    type: 'item',
    componentConfiguration: 'AU1'
  }, {
    type: 'item',
    componentConfiguration: 'WM1'
  }, {
    type: 'item',
    componentConfiguration: 'FL1'
  }, {
    key: 'subtitle',
    type: 'subtitle',
    translations: {
      headline: 'bc-dashboard:JobofferEdit.SettingsHeadline',
      headlineFreemium: 'bc-dashboard:JobofferEdit.SettingsHeadlineFreemium',
      description: 'bc-dashboard:JobofferEdit.SettingsDescription'
    }
  }],
  registration: [],
  view: {
    primaryAttribute: 'transportDistances'
  }
};
exports.bc = bc;
var _default = {
  pc: pc,
  bc: bc
};
exports.default = _default;