"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExcludeFromSsr = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var useExcludeFromSsr = function useExcludeFromSsr() {
  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      loaded = _useState2[0],
      setLoaded = _useState2[1];

  (0, _react.useEffect)(function () {
    setLoaded(true);
  }, []);
  return function (children) {
    return loaded ? children : null;
  };
};

exports.useExcludeFromSsr = useExcludeFromSsr;